//import { isNotNullAndUndefined } from "./utils";

import { Browser } from "@syncfusion/ej2-base";

export enum WorkESIOHosts {
    dev = 'dev.app.workesio.com',
    beta = 'beta.app.workesio.com',
    preprod = 'preprod.app.workesio.com',
    prod = 'app.workesio.com',
    archive = 'archive.app.workesio.com',
    localhost = 'localhost'
}


export class Constants {
    //static baseAPIUrl = (process.env.REACT_APP_BASE_API_URL !== null && process.env.REACT_APP_BASE_API_URL !== undefined) ? process.env.REACT_APP_BASE_API_URL : 'http://localhost:5001/';

    static baseAPIUrl: string;
    static setBaseAPIUrl() {
        if (window.location.hostname === WorkESIOHosts.dev) {
            Constants.baseAPIUrl = 'https://api-dev.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.beta) {
            Constants.baseAPIUrl = 'https://api-beta.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.preprod) {
            Constants.baseAPIUrl = 'https://api-preprod.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.prod) {
            Constants.baseAPIUrl = 'https://api.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.archive) {
            Constants.baseAPIUrl = 'https://api-archive.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.localhost) {
            Constants.baseAPIUrl = 'https://api-dev.workesio.com/';
            //Constants.baseAPIUrl = 'https://localhost:7084/';
        }
        else {
            Constants.baseAPIUrl = 'https://api-dev.workesio.com/';
            //Constants.baseAPIUrl = 'https://localhost:7084/';
        }
    }

    static baseRAFXeroUrl: string;
    static setBaseRAFXeroUrl() {
        if (window.location.hostname === WorkESIOHosts.dev) {
            Constants.baseRAFXeroUrl = 'https://dev-integrate-xero.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.beta) {
            Constants.baseRAFXeroUrl = 'https://beta-integrate-xero.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.preprod) {
            Constants.baseRAFXeroUrl = 'https://preprod-integrate-xero.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.prod) {
            Constants.baseRAFXeroUrl = 'https://integrate-xero.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.archive) {
            Constants.baseRAFXeroUrl = 'https://archive-integrate-xero.workesio.com/';
        }
        else if (window.location.hostname === WorkESIOHosts.localhost) {
            Constants.baseRAFXeroUrl = 'https://dev-integrate-xero.workesio.com/';
            //Constants.baseRAFXeroUrl = 'https://localhost:5001/';
        }
        else {
            //Constants.baseRAFXeroUrl = 'https://localhost:5001/'
            Constants.baseRAFXeroUrl = 'https://dev-integrate-xero.workesio.com/';
        }
    }


    static cookieName = 'ACSESSIONC';
    static HorizontalRAFFormElement = 'HorizontalRAFFormElement';
    static VerticalRAFFormElement = 'VerticalRAFFormElement';
    static DropdownFetchCount = 50;
    static ListViewFetchCount = 20;
    //static MaxDate = new Date(Date.UTC(9998, 11, 31));
    static MaxDate = new Date(Date.UTC(9999, 11, 31));
    //static MinDate = new Date(Date.UTC(1970, 1, 1));
    static MinDate = new Date(Date.UTC(1, 0, 1));
    //static MaxDate = new Date(9999, 11, 31);
    static RAFObject_NoContent = 'NoContent';
    static MyOrganizationPortal = 'my organization';
    static idSuffix = '0-0_0';
}

// Call the method to set the baseAPIUrl
Constants.setBaseAPIUrl();
Constants.setBaseRAFXeroUrl();

export class ContentType {
    static applicationFormUrlEncoded = 'application/x-www-form-urlencoded';
    static applicationJson = 'application/json';
}
export class RAFDatePickerViewFormat {
    static DATE = 'dd/MM/yyyy';
    static DATETIME = 'dd/MM/yyyy h:mm a';
}
export class MomentFormats {
    static DATE = 'DD/MM/YYYY';
    static DATEMMMM = 'DD MMMM YYYY';
    static DATETIME = 'DD/MM/YYYY  h:mm a';
    static DATETIME24 = 'dd/MM/yyyy HH:mm';
    static TIME = 'h:mm a';
}
export enum RAFLayout {
    OneColumnLayout = 1,
    TwoColumnLayout = 2,
    ThreeColumnLayout = 3,
    FourColumnLayout = 4
}

export class StorageKey {
    static ShowSideMenu = '_ShowSideMenu';
    static ShowSideMenu2 = '_ShowSideMenu2';
    static MenuModel = 'MenuModel';
    static Terminologies = 'Terminologies';
    static allViews_modulename = '_allViews_';
    static allUserFocus_MyList_modulename = '_allUserFocus_MyList_';
    static adminViews_modulename = '_adminViews_';
    static allReportView_modulename = '_allReportView_';
    static allReportView_modulename_reportType = '_allReportView_reportType_';
    static allDashboardView_modulename = '_allDashboardView_';
    static recentViewId_modulename = '_recentViewId_';
    static clterms_modulename = '_clterms_';
    static viewAttribs_modulename = '_viewAttribs_';
    static allAttribs_modulename = '_allAttribs_';
    static rafgrid_viewId = 'rafgrid_';
    static currentBusinessUnit = 'currentBusinessUnit';
    static currentUser = 'currentUser';
    static budropdownmenu = 'budropdownmenu';
    static formAttributes_formType = 'formAttributes_';
    static templatFormAttributes__formType = 'templatFormAttributes_';
    static userDefinition = 'userDefinition';
    static returnUrl = 'returnurl';
    static allUsers = '_allUsers';
    static allUserGroups = '_allUserGroups';
    static myUserGroups = '_myUserGroups';
    static recentViewId_admin_modulename = '_recentViewId_admin_';
    static all_Permissions = '_all_Permissions';
    static currentOrganisation = 'currentOrganisation';
    //static allForms = 'allForms';
    static documentFolderArray = '_documentFolderArray_';
    static selectedBPTypeItemUID = '_selectedBPTypeItemUID_';
    static simpleEntity_modulename = '_SimpleEntity_';
    static entity_modulename = '_Entity_';
    static objPageLayout_UID = '_objPageLayout_';
    static pageLayoutList_pageTypeentity = '_pageLayoutList_';
    static PortalList = '_portalList';
    static CurrentPortal = '_currentPortal';
    static TenantSourcePartners = '_tenantSourcePartners';
    static UserTenantSettings = '_userTenantSettings';
    static searchedCustomFilter_modulename = '_searchedCustomFilter_';
}


export enum RAFTenantName {
    skytrust = 'skytrust',
    dev = 'dev',
    Propelsa = 'Propelsa',
    CareESIO = 'CareESIO',
}
export enum RAFViewVisiblity {
    IsPrivate = 'Visible only to me',
    IsPublic = 'Shared with others',
}

export enum activitySectionType {
    Timeline = "Timeline",
    Notes = "Notes",
    AllComments = "All Comments",
    AllAttachments = "All Attachments",
    Tasks = "Tasks",
}
export enum SFColumnType { //syncfusion ColumnDirective 
    string = "string",
    number = "number",
    boolean = "boolean",
    date = "date",
    datetime = "datetime",
    dropdown = "dropdown", // there is no type dropdown in syncfusion ColumnDirective.. this SFColumnType used only for filterTemplate
}

export enum RAFInputType {
    Text = 'Text',
    Number = 'Numeric',
    DropDownList = 'DropDownList',
    LookupDropdownList = 'LookupDropdownList',
    Date = 'Date',
    AutoComplete = 'AutoComplete',
    Boolean = 'Boolean',
}
export enum RAFInputComponentType {
    TextBox = 'Text Box',
    TextArea = 'Text Area',
    DatePicker = 'Date Picker',
    Dropdown = 'Dropdown',
    MultiSelect = 'MultiSelect'
}

export class RAFDatePresets {
    //day
    static Yesterday = "yesterday";
    static Today = "today";
    static Tomorrow = "tomorrow";
    static TillYesterday = "tillyesterday";
    static TillToday = "tilltoday";
    static TillNow = "tillnow";
    static Last7Days = "last7";
    static Last15Days = "last15";
    static Last30Days = "last30";
    static Last45Days = "last45";
    static Last60Days = "last60";
    static Last90Days = "last90";
    static Last120Days = "last120";
    static Last180Days = "last180";
    static Next7Days = "next7";
    static Next15Days = "next15";
    static Next30Days = "next30";
    static Next45Days = "next45";
    static Next60Days = "next60";
    static Next90Days = "next90";
    static Next120Days = "next120";
    //Calendar Week
    static LastWeek = "lastweek";
    static ThisWeek = "thisweek";
    static NextWeek = "nextweek";
    //Calendar Month
    static LastMonth = "lastmonth";
    static ThisMonth = "thismonth";
    static NextMonth = "nextmonth";
    static CurrentAndPreviousMonth = "lastthismonth";
    static CurrentAndNextMonth = "thisnextmonth";
    //Calendar Year
    static CurrentCalendarYear = "cury";
    static PreviousCalendarYear = "prevy";
    static Previous2CalendarYear = "prev2y";
    static Ago2CalendarYear = "ago2y";
    static NextCalendarYear = "nexty";
    static CurrentAndPreviousCalendarYear = "prevcury";
    static CurrentAndPrevious2CalendarYear = "prevcur2y";
    static CurrentAndNextCalendarYear = "curnexty";
    //Calendar Quarter
    static CurrentCalendarQuarter = "currentq";
    static CurrentAndNextCalendarQuarter = "curnextq";
    static CurrentAndPreviousCalendarQuarter = "curprevq";
    static NextCalendarQuarter = "nextq";
    static PreviousCalendarQuarter = "prevq";
    static CurrentAndNext3CalendarQuarter = "curnext3q";

}

export class RAFHeaderNames {
    static Authorization = 'Authorization';
    static BusinessUnitUID = 'buuid';
    static Domain = 'SubDomain';
}


export enum RAFActionStatus {
    Scheduled = 'Scheduled',
    Planned = 'Planned',
    Accepted = 'Accepted',
    Completed = 'Completed',
    Deferred = 'Deferred',
    Postponed = 'Postponed',
    Approved = "Approved",
    Rejected = "Rejected",
    Inprocess = "Inprocess",
    Done = 'Done',
    NotDone = 'Not done',
    Refused = 'Refused',
    NoTime = 'No time',
    Pending = 'Pending',
    Paid = 'Paid',
    Ready = "Ready",
    Invoiced = 'Invoiced',
    InvoiceSent = 'Invoice Sent',
    PaymentReceived = 'Payment Received',
    NotReady = 'Not Ready',
    Unassigned = 'Unassigned',
    InProgress = 'In Progress',
    Active = 'Active',
    Resolved = 'Resolved',
    Taken = 'Taken',
    PartiallyTaken = 'Partially taken',
    NotTaken = 'Not taken',
    Submitted = 'Submitted',
    Reported = 'Reported',
    OfferSent = 'Offer Sent',
    Acknowledged = 'Acknowledged',
    Applied = 'Applied'
}

export enum RAFShiftActionStatus {
    Scheduled = 'Scheduled',
    Accepted = 'Accepted',
    OfferSent = 'Offer Sent',
    Planned = 'Planned',
    Completed = 'Completed',
    Deferred = 'Deferred',
    Postponed = 'Postponed',
    Approved = "Approved",
    Rejected = "Rejected",
    Inprocess = "Inprocess",
    Done = 'Done',
    NotDone = 'Not done',
    Refused = 'Refused',
    NoTime = 'No time',
    Pending = 'Pending',
    Paid = 'Paid',
    //Ready = "Ready to Process",
    Ready = "Ready to Invoice",
    Invoiced = 'Invoiced',
    InvoiceSent = 'Invoice Sent',
    PaymentReceived = 'Payment Received',
    NotReady = 'Not Ready',
    NotBilled = 'Not Billed',
    Unassigned = 'Unassigned',
    InProgress = 'InProgress',
    Active = 'Active',
    Resolved = 'Resolved',
    Taken = 'Taken',
    PartiallyTaken = 'Partially taken',
    NotTaken = 'Not taken',
    Submitted = 'Submitted',
    AwaitingForApproval = 'Awaiting Approval',
    Cancelled = 'Cancelled',
    XeroSyncSuccess = 'Xero Sync Success',
    XeroSyncFailed = 'Xero Sync Failed',
    XeroSyncPending = 'Xero Sync Pending',
    AddedtoInvoice = 'Added to Invoice',
    InvoicedDirectly = 'Invoiced Directly',
    InvoicedtoXero = 'Invoiced to Xero',
}

export class RAFStatusNameWithColor {
    static Planned = { Value: 'Planned', DisplayName: 'Planned', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Scheduled = { Value: 'Scheduled', DisplayName: 'Scheduled', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Accepted = { Value: 'Accepted', DisplayName: 'Accepted', Color: '#2ecc71', LightColor: '#98FB98' };
    static Completed = { Value: 'Completed', DisplayName: 'Completed', Color: '#27ae60', LightColor: '#90EE90' };
    static Submitted = { Value: 'Submitted', DisplayName: 'Submitted', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Uploaded = { Value: 'Uploaded', DisplayName: 'Uploaded', Color: '#27ae60', LightColor: '#90EE90' };
    static Resolved = { Value: 'Resolved', DisplayName: 'Resolved', Color: '#27ae60', LightColor: '#90EE90' };
    static Done = { Value: 'Done', DisplayName: 'Done', Color: '#27ae60', LightColor: '#90EE90' };
    static Approved = { Value: 'Approved', DisplayName: 'Approved', Color: '#9b59b6', LightColor: '#D3D3D3' };
    static Acknowledged = { Value: 'Acknowledged', DisplayName: 'Acknowledged', Color: '#27ae60', LightColor: '#27ae60' };
    static Rejected = { Value: 'Rejected', DisplayName: 'Rejected', Color: '#8B0000', LightColor: '#FFC0CB' };
    static Cancelled = { Value: 'Cancelled', DisplayName: 'Cancelled', Color: '#8B0000', LightColor: '#FFC0CB' };
    static Inprocess = { Value: 'Inprocess', DisplayName: 'In Progress', Color: '#f39c12', LightColor: '#FFD700' };
    static InProgress = { Value: 'InProgress', DisplayName: 'In Progress', Color: '#f39c12', LightColor: '#FFD700' };
    static "Not Uploaded" = { Value: 'NotUploaded', DisplayName: 'Not Uploaded', Color: '#F8A830', LightColor: '#F8A830' };
    static 'In Progress' = { Value: 'Inprocess', DisplayName: 'In Progress', Color: '#f39c12', LightColor: '#FFD700' };
    static Paid = { Value: 'Paid', DisplayName: 'Paid', Color: '#0078d6', LightColor: '#ADD8E6' };
    static 'Not Ready' = { Value: 'Not Ready', DisplayName: 'Not Ready', Color: '#f39c12', LightColor: '#FFD700' };
    static 'Not Billed' = { Value: 'Not Billed', DisplayName: 'Not Billed', Color: '#f39c12', LightColor: '#FFD700' };
    static "Not Started" = { Value: "Not Started", DisplayName: "Not Started", Color: '#f39c12', LightColor: '#FFD700' };
    static 'Ready to Process' = { Value: 'Ready to Process', DisplayName: 'Ready to Process', Color: '#0078d6', LightColor: '#ADD8E6' };
    static 'Ready to Invoice' = { Value: 'Ready to Invoice', DisplayName: 'Ready to Invoice', Color: '#0078d6', LightColor: '#ADD8E6' };
    static Invoiced = { Value: 'Invoiced', DisplayName: 'Invoiced', Color: '#e74c3c', LightColor: '#FFA07A' };
    static 'Invoice Sent' = { Value: 'Invoice Sent', DisplayName: 'Invoice Sent', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Payment Received' = { Value: 'Payment Received', DisplayName: 'Payment Received', Color: '#9b59b6', LightColor: '#D3D3D3' };
    static 'Not done' = { Value: 'Not done', DisplayName: 'Not done', Color: '#f9a825', LightColor: '#D3D3D3' };
    static 'No time' = { Value: 'No time', DisplayName: 'No time', Color: '#666666', LightColor: '#D3D3D3' };
    static Refused = { Value: 'Refused', DisplayName: 'Refused', Color: '#b71c1c', LightColor: '#b71c1c' };
    static 'Not taken' = { Value: 'Not taken', DisplayName: 'Not taken', Color: '#c62828', LightColor: '#b71c1c' };
    static 'Partially taken' = { Value: 'Partially taken', DisplayName: 'Partially taken', Color: '#fbc02d', LightColor: '#b71c1c' };
    static Taken = { Value: 'Taken', DisplayName: 'Taken', Color: '#004d40', LightColor: '#b71c1c' };
    static Unassigned = { Value: 'Unassigned', DisplayName: 'Unassigned', Color: '#e74c3c', LightColor: '#FFA07A' };
    static Published = { Value: 'Published', DisplayName: 'Published', Color: '#0078d6', LightColor: '#FFA07A' };
    static Draft = { Value: 'Draft', DisplayName: 'Draft', Color: '#666666', LightColor: '#FFA07A' };
    static Pending = { Value: 'Pending', DisplayName: 'Pending', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Awaiting Approval' = { Value: 'Awaiting Approval', DisplayName: 'Awaiting Approval', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Notes Missing' = { Value: 'Notes Missing', DisplayName: 'Notes Missing', Color: '#b71c1c', LightColor: '#FFA07A' };
    static 'Minimal' = { Value: 'Minimal', DisplayName: 'Minimal', Color: '#666666', LightColor: '#FFA07A' };
    static 'Short' = { Value: 'Short', DisplayName: 'Short', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Moderate' = { Value: 'Moderate', DisplayName: 'Moderate', Color: '#f39c12', LightColor: '#FFD700' };
    static 'Detailed' = { Value: 'Detailed', DisplayName: 'Detailed', Color: '#0078d6', LightColor: '#ADD8E6' };
    static 'Elaborate' = { Value: 'Elaborate', DisplayName: 'Elaborate', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Xero Sync Success' = { Value: 'Xero Sync Success', DisplayName: 'Xero Sync Success', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Xero Sync Failed' = { Value: 'Xero Sync Failed', DisplayName: 'Xero Sync Failed', Color: '#e74c3c', LightColor: '#FFA07A' };
    static 'Xero Sync Pending' = { Value: 'Xero Sync Pending', DisplayName: 'Xero Sync Pending', Color: '#5B39F0', LightColor: '#DED7FC' };
    static 'Added to Invoice' = { Value: 'Added to Invoice', DisplayName: 'Added to Invoice', Color: '#e74c3c', LightColor: '#FFA07A' };
    static 'Invoiced Directly' = { Value: 'Invoiced Directly', DisplayName: 'Invoiced Directly', Color: '#2ecc71', LightColor: '#98FB98' };
    static 'Invoiced to Xero' = { Value: 'Invoiced to Xero', DisplayName: 'Invoiced to Xero', Color: '#9b59b6', LightColor: '#D3D3D3' };
    static 'Offer Sent' = { Value: 'Offer Sent', DisplayName: 'Offer Sent', Color: '#9b59b6', LightColor: '#D3D3D3' };
    static 'Shift' = { Value: 'Shift', DisplayName: 'Shift', Color: '#f39c12', LightColor: '#FFD700' };
    static 'Booking' = { Value: 'Booking', DisplayName: 'Booking', Color: '#0078d6', LightColor: '#ADD8E6' };

}

export class RAFGenderConstant {
    static male = { value: 'male', DisplayName: 'Male', iconCss: 'far fa-mars', iconCss_l: 'fal fa-mars' };
    static female = { value: 'female', DisplayName: 'Female', iconCss: 'far fa-venus', iconCss_l: 'fal fa-venus' };
    static non_binary = { value: 'non_binary', DisplayName: 'Non-binary', iconCss: 'far fa-transgender', iconCss_l: 'fal fa-transgender' };
    static other = { value: 'other', DisplayName: 'Non-binary', iconCss: 'far fa-transgender', iconCss_l: 'fal fa-transgender' };
}
export class RAFTaskTypeWithColor {
    static 'To-Do' = {
        Value: 'To-Do', DisplayName: 'To-Do', Color: '#378DF2', className: 'state_primary',
        svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M7.33331 13H14" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.33331 8.33331H14" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.33331 3.66669H14" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 3.66665L2.66667 4.33331L4.66667 2.33331" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 8.33333L2.66667 9L4.66667 7" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 13L2.66667 13.6667L4.66667 11.6667" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
    };
    static Review = { Value: 'Review', DisplayName: 'Review', Color: '#CD4D05', className: '#ADD8E6' };
    static Approval = { Value: 'Approval', DisplayName: 'Approval', Color: '#4C30C8', className: '#ADD8E6' };
    static Action = {
        Value: 'Action', DisplayName: 'Action', Color: '#F65C06', className: 'brand_secondary',
        svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M5.99998 14.6667H9.99998C13.3333 14.6667 14.6666 13.3333 14.6666 10V6.00001C14.6666 2.66668 13.3333 1.33334 9.99998 1.33334H5.99998C2.66665 1.33334 1.33331 2.66668 1.33331 6.00001V10C1.33331 13.3333 2.66665 14.6667 5.99998 14.6667Z" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.88666 9.66L6.47332 7.6C6.69999 7.30666 7.11999 7.25333 7.41332 7.48L8.63332 8.44C8.92666 8.66666 9.34666 8.61333 9.57332 8.32666L11.1133 6.34" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
    };
    static Event = {
        Value: 'Event', DisplayName: 'Event', Color: '#2C3B71', className: '#ADD8E6'
    };
    static Appointment = {
        Value: 'Appointment', DisplayName: 'Appointment', Color: '#5B39F0', className: 'brand_tertiary',
        svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M5.33331 1.33333V3.33333" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.6667 1.33333V3.33333" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.33331 6.06H13.6666" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 5.66666V11.3333C14 13.3333 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3333 2 11.3333V5.66666C2 3.66666 3 2.33333 5.33333 2.33333H10.6667C13 2.33333 14 3.66666 14 5.66666Z" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.4632 9.13334H10.4691" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.4632 11.1333H10.4691" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.99697 9.13334H8.00296" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.99697 11.1333H8.00296" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.52956 9.13334H5.53555" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.52956 11.1333H5.53555" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
    };
    static Call = {
        Value: 'Call', DisplayName: 'Call', Color: '#F7BC08', className: 'brand_quaternary',
        svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M14.6466 12.22C14.6466 12.46 14.5933 12.7067 14.48 12.9467C14.3666 13.1867 14.22 13.4133 14.0266 13.6267C13.7 13.9867 13.34 14.2467 12.9333 14.4133C12.5333 14.58 12.1 14.6667 11.6333 14.6667C10.9533 14.6667 10.2266 14.5067 9.45998 14.18C8.69331 13.8533 7.92665 13.4133 7.16665 12.86C6.39998 12.3 5.67331 11.68 4.97998 10.9933C4.29331 10.3 3.67331 9.57333 3.11998 8.81333C2.57331 8.05333 2.13331 7.29333 1.81331 6.54C1.49331 5.78 1.33331 5.05333 1.33331 4.35999C1.33331 3.90666 1.41331 3.47333 1.57331 3.07333C1.73331 2.66666 1.98665 2.29333 2.33998 1.95999C2.76665 1.53999 3.23331 1.33333 3.72665 1.33333C3.91331 1.33333 4.09998 1.37333 4.26665 1.45333C4.43998 1.53333 4.59331 1.65333 4.71331 1.82666L6.25998 4.00666C6.37998 4.17333 6.46665 4.32666 6.52665 4.47333C6.58665 4.61333 6.61998 4.75333 6.61998 4.88C6.61998 5.04 6.57331 5.19999 6.47998 5.35333C6.39331 5.50666 6.26665 5.66666 6.10665 5.82666L5.59998 6.35333C5.52665 6.42666 5.49331 6.51333 5.49331 6.62C5.49331 6.67333 5.49998 6.71999 5.51331 6.77333C5.53331 6.82666 5.55331 6.86666 5.56665 6.90666C5.68665 7.12666 5.89331 7.41333 6.18665 7.76C6.48665 8.10666 6.80665 8.46 7.15331 8.81333C7.51331 9.16666 7.85998 9.49333 8.21331 9.79333C8.55998 10.0867 8.84665 10.2867 9.07331 10.4067C9.10665 10.42 9.14665 10.44 9.19331 10.46C9.24665 10.48 9.29998 10.4867 9.35998 10.4867C9.47331 10.4867 9.55998 10.4467 9.63331 10.3733L10.14 9.87333C10.3066 9.70666 10.4666 9.58 10.62 9.5C10.7733 9.40666 10.9266 9.36 11.0933 9.36C11.22 9.36 11.3533 9.38666 11.5 9.44666C11.6466 9.50666 11.8 9.59333 11.9666 9.70666L14.1733 11.2733C14.3466 11.3933 14.4666 11.5333 14.54 11.7C14.6066 11.8667 14.6466 12.0333 14.6466 12.22Z" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10"/>
</svg>`
    };
    static Note = {
        Value: 'Note', DisplayName: 'Note', Color: '#021454', className: 'anchor',
        svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M5.33331 1.33333V3.33333" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.6667 1.33333V3.33333" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.66669 8.66667H10" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.66669 11.3333H8.00002" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.6667 2.33333C12.8867 2.45333 14 3.3 14 6.43333V10.5533C14 13.3 13.3333 14.6733 10 14.6733H6C2.66667 14.6733 2 13.3 2 10.5533V6.43333C2 3.3 3.11333 2.46 5.33333 2.33333H10.6667Z" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>` };
    static Email = {
        Value: 'Email', DisplayName: 'Email', Color: '#4FBAFC', className: 'state_info',
        svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M2 3.5C2 2.67157 2.67157 2 3.5 2H12.5C13.3284 2 14 2.67157 14 3.5V12.5C14 13.3284 13.3284 14 12.5 14H3.5C2.67157 14 2 13.3284 2 12.5V3.5Z" 
        stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 3.5L8 8.5L2 3.5" 
        stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
` };
}
export enum RAFTaskType {
    ToDo = 'To-Do',
    Review = 'Review',
    Approval = 'Approval',
    Action = 'Action',
    Event = 'Event',
    //Shift = 'Shift',
    Appointment = 'Appointment',
    Call = 'Call',
    Note = 'Note',
    Email = 'Email',
}

export enum RAFTaskSubType {
    Prevention = 'Prevention',
    Correction = 'Correction',
    Inbound = 'Inbound',
    Outbound = 'Outbound',
    Task = 'Task',
    Improvement = 'Improvement',
    Sent = 'Sent',
    Received = 'Received',
}

export enum RAFEmailStatus {
    Sent = 'Sent',
    Received = 'Received',
    Queued = 'Queued',
    Saved = 'Saved',
    Error = 'Error'
}

export enum Action {
    Added = 1,
    Changed = 2,
    Deleted = 3,
    Joined = 4,
    Left = 5
}

export enum RAFLoggedInUser {
    UserId = 'a435b10d-f558-4d60-9457-9f0f36c8c281',
    Username = 'Aaron',
}

export enum RAFActions {
    Next = 'Next',
    Upcoming = 'Upcoming',
    Completed = 'Completed',
    All = 'All',
}

export class SystemVariable {
    static CURRENTUSER = "$CurrentUser";
    static CURRENTUSERGUID = "$CurrentUserGuid";
    static CURRENTUSER_DISPLAYNAME = "@Me";
}

// export enum RAFModuleName {
//     Account = 'Account',
//     Contact = 'Contact',
//     Deal = 'Deal',
//     Ticket = 'Ticket',
//     ContentLibrary = 'Content Library',
//     Documents = 'Documents',
//     EmailAttachment = 'Email Attachment',
//     Email = 'Email',
//     Lead = 'Lead',
//     User = 'User',
//     Complaint = 'Complaint',
//     BusinessProcessStep = 'Business Process Step',
//     BusinessProcess = 'Business Process',
//     Task = "Task",
//     ServiceTransaction = "Service Transaction",
//     Activity = "Notes",
// }

// export enum RAFEntityName {
//     Account = 'account',
//     Contact = 'contact',
//     Deal = 'deal',
//     Ticket = 'ticket',
//     Complaint = 'complaint',
//     Task = 'task',
//     ServiceTransaction = 'service_transaction',
//     BusinessProcess = 'business_process',
//     BusinessProcessStep = 'business_process_step',
//     BusinessProcessTemplate = 'business_process_template',
//     //BusinessProcessType = 'business_process_type',
//     BusinessProcessType = 'category',
//     Category = 'category',
//     BusinessProcessStepTemplate = 'business_process_step_template',
//     BusinessProcessStage = 'business_process_stage',
//     BusinessRule = 'businessrule',
//     User = 'user',
//     Role = 'permission_group',
//     ContentLibrary = 'content_library',
//     VersionHistory = 'version_history',
//     Email = 'email',
//     Team = 'team',
//     UserSignature = 'user_signature',
//     Contract = 'contract',
//     FormLibrary = 'form_library',
//     FormLibraryStatic = 'form_library_static',
//     Entity = 'entity',
//     Template = 'template',
//     Portal = 'portal',
//     PortalItem = 'portal_item',
//     RecordCategory = 'record_category',
//     City = 'city',
//     State = 'state',
//     Country = 'country',
//     Note = 'note',
//     Document = 'document',
//     DataList = 'datalist',
//     FormData = 'form_data',
//     Report = 'report',
//     Dashboard = 'dashboard',
//     View = 'view',
//     BusinessRuleTrigger = 'business_rule_trigger',
//     PageLayout = 'pagelayout',
//     UserFocus = 'user_focus',
//     DealStage = 'deal_stage',
//     Department = 'department',
//     Location = 'location',
//     Product = 'product',
//     Suggestion = 'suggestion',
//     Import = 'import',
//     TaskTitle = 'task_title',
//     ExtraInfoJson = 'extra_info_json',
//     Activity = 'activity',
//     StandardizedLibrary = 'standardized_library',
//     Register = 'shift_log',
//     PriceList = 'pricelist',
// }

// export function getURLPrefixByModuleName(moduleName: string, defaultValue?: string, entityUID?: string, entityName?: string) {
//     //let outVal = !IsNullOrWhiteSpace(defaultValue) ? defaultValue : 'DataList';
//     //     const options: string[] = Object.values(RAFEntityName);
//     //         if(options.includes(moduleName.toLowerCase())){
//     //  outVal = ConvertSystemName(moduleName)
//     //         }else{
//     //              outVal = 'DataList';
//     //         }
//     let outVal = 'DataList';
//     if (!IsNullOrWhiteSpace(moduleName)) {
//         switch (moduleName.toLowerCase()) {
//             case RAFEntityName.Account:
//                 outVal = "Account";
//                 break;
//             case RAFEntityName.Contact:
//                 outVal = "Contact";
//                 break;
//             case RAFEntityName.Deal:
//                 outVal = "Deal";
//                 break;
//             case RAFEntityName.Ticket:
//                 outVal = "Ticket";
//                 break;
//             case RAFEntityName.Complaint:
//                 outVal = "Complaint";
//                 break;
//             case RAFEntityName.Task:
//                 outVal = "Task";
//                 break;
//             case RAFEntityName.ServiceTransaction:
//                 outVal = "ServiceTransaction";
//                 break;
//             case RAFEntityName.BusinessProcess:
//                 outVal = "BusinessProcess";
//                 break;
//             case RAFEntityName.BusinessProcessStep:
//                 outVal = "BusinessProcessStep";
//                 break;
//             case RAFEntityName.BusinessProcessTemplate:
//                 outVal = "BusinessProcessTemplate";
//                 break;
//             case RAFEntityName.BusinessProcessType:
//                 outVal = "Category";
//                 break;
//             case RAFEntityName.BusinessProcessStepTemplate:
//                 outVal = "BusinessProcessStepTemplate";
//                 break;
//             case RAFEntityName.BusinessProcessStage:
//                 outVal = "BusinessProcessStage";
//                 break;
//             case RAFEntityName.BusinessRule:
//                 outVal = "BusinessRule";
//                 break;
//             case RAFEntityName.User:
//                 outVal = "User";
//                 break;
//             case RAFEntityName.Role:
//                 outVal = "PermissionGroup";
//                 break;
//             case RAFEntityName.ContentLibrary:
//                 outVal = "ContentLibrary";
//                 break;
//             case RAFEntityName.VersionHistory:
//                 outVal = "VersionHistory";
//                 break;
//             case RAFEntityName.Email:
//                 outVal = "Email";
//                 break;
//             case RAFEntityName.Team:
//                 outVal = "Team";
//                 break;
//             case RAFEntityName.UserSignature:
//                 outVal = "UserSignature";
//                 break;
//             case RAFEntityName.Contract:
//                 outVal = "Contract";
//                 break;
//             case RAFEntityName.FormLibrary:
//                 outVal = "FormLibrary";
//                 break;
//             case RAFEntityName.Entity:
//                 outVal = "Entity";
//                 break;
//             case RAFEntityName.Template:
//                 outVal = "Template";
//                 break;
//             case RAFEntityName.Portal:
//                 outVal = "Portal";
//                 break;
//             case RAFEntityName.PortalItem:
//                 outVal = "PortalItem";
//                 break;
//             case RAFEntityName.RecordCategory:
//                 outVal = "RecordCategory";
//                 break;
//             case RAFEntityName.Document:
//                 outVal = "Document";
//                 break;
//             case RAFEntityName.FormData:
//                 outVal = "FormData";
//                 break;
//             case RAFEntityName.Report:
//                 outVal = "Report";
//                 break;
//             case RAFEntityName.Dashboard:
//                 outVal = "Dashboard";
//                 break;
//             case RAFEntityName.View:
//                 outVal = "View";
//                 break;
//             case RAFEntityName.BusinessRuleTrigger:
//                 outVal = "BusinessRuleTrigger";
//                 break;
//             case RAFEntityName.PageLayout:
//                 outVal = "PageLayout";
//                 break;
//             case RAFEntityName.UserFocus:
//                 outVal = "UserFocus";
//                 break;
//             case RAFEntityName.DealStage:
//                 outVal = "DealStage";
//                 break;
//             case RAFEntityName.Department:
//                 outVal = "Department";
//                 break;
//             case RAFEntityName.Location:
//                 outVal = "Location";
//                 break;
//             case RAFEntityName.Product:
//                 outVal = "Product";
//                 break;
//             case RAFEntityName.Suggestion:
//                 outVal = "Suggestion";
//                 break;
//             case RAFEntityName.Import:
//                 outVal = "Import";
//                 break;
//             case RAFEntityName.TaskTitle:
//                 outVal = "TaskTitle";
//                 break;
//             default:
//                 //outVal = ConvertToURLValue(moduleName);
//                 break;
//         }
//     }
//     outVal = !IsNullOrWhiteSpace(defaultValue) ? defaultValue : (!IsNullOrWhiteSpace(entityUID) || !IsNullOrWhiteSpace(entityName)) ? 'DataList' : outVal;
//     return outVal;
// };

export enum RAFAttributeName {
    UID = 'uid',
    RecordInfo = 'record_info',
    //Category = 'category',
    Entity = 'entity',
    FormLibrary = 'form_library',
    TagsListJson = 'tags_list_json',
    TagsListJsonText = 'tags_list_json_text',
    Portal = 'portal',
    BusinessProcess = 'business_process',
    ProcessStatus = 'process_status',

}

export enum RAFModuleURLName {
    Account = 'Company',
    Contact = 'Contact',
    Deal = 'Deal',
    Ticket = 'Ticket',
}

export enum RAFViewType {
    Lookup = "lookup",
    Default = "default",
    RelatedTo = "relatedto",
    Report = "report",
    Group = "group",
    SharedView = "SharedView",
    SystemView = "SystemView",
    MyView = "MyView",
}

export enum RAFReportType {
    Folder = 'Folder',
    Pivot = 'Pivot',
    Chart = 'Chart',
    Analytic = 'Analytic',
    Table = 'Table',
    Report = 'Report',
    Group = 'Group',
    Dashboard = 'Dashboard',
    List = 'List',
    Metric = 'Metric',
    Default = 'default',
    Tags = 'Tags',
    Standard = 'Standard',
}

export enum RAFReportTypeDisplayName {
    Visualization = 'Visualization',
    Analytic = 'Analytics',
    Table = 'Templates',
    Dashboard = 'Dashboards',
    List = 'List',
    View = 'Views',
    // Record = 'Processes',
    Record = 'Runs By Status',
    Tags = 'Tags',
    Gallery = 'Gallery',
    Library = 'Library',
}

export enum RAFViewTypeDisplayName {
    SharedView = "Shared View",
    SystemView = "System View",
    MyView = "My View",
}

export enum RAFReportViewType {
    Table = 'Table',
    Metric = 'Metric',
    Group = 'Group',
    Pivot = 'Pivot',
    Chart = 'Chart',
}

export enum RAFChartType {
    VerticalBar = 'Vertical Bar',
    VerticalBarStacked = 'Vertical Bar - Stacked',
    VerticalBarStacked100 = 'Vertical Bar - Stacked to 100%',
    Line = 'Line',
    Pie = 'Pie',
    Doughnut = 'Doughnut',
    Funnel = 'Funnel',
    Bar = 'Bar',
    Table = 'Table',
    Column = 'Column',
}

export class RAFTreeViewMenu {
    UID?: string;
    DisplayName?: string;
    Name?: string;
    Category?: string;
    ParentID?: string;
    IsPrivate?: boolean;
    IsSystem?: boolean;
    ViewType?: string;
    IsDefault?: boolean;
    PermissionName?: string;
    IsFocused?: boolean;
    FocusUserUID?: string;
    //Permission?: string;
    //ChartType?: string;
}

export enum RAFVisualType {
    Table = 'Table',
    Metric = 'Metric',
    Chart = 'Chart',
}

export enum RAFAggregeateFunction {
    Count = 'count',
    Sum = 'sum',
    Avg = 'avg',
    Max = 'max',
    Min = 'min'
}

export enum RAFDateFunction {
    Week = "week",
    Month = "month",
    MonthName = "month name",
    MonthNameYear = "month name year",
    Year = "year",
    Day = "day",
    DayName = "day name",
    Age = "age",
    Quarter = "quarter"
}

export enum RAFDocumentType {
    File = "File",
    Folder = "Folder",
    Page = "Page", //Page = "WebDoc",
    Collection = "Collection",
}

export enum RAFDocumentTypeDisplayname {
    File = 'File',// "Uploaded File",
    Folder = "Folder",
    Page = 'Page', // Page = 'Page'// "Web Doc",
    Collection = "Collection",
}


export class RAFDocumentTypeName {
    static documentTypeConstant(Id: string, DisplayName: string, CollectionName: string, IconClass: string) {
        return { Id, DisplayName, CollectionName, IconClass };
    }

    static File = RAFDocumentTypeName.documentTypeConstant('File', 'File', 'Files', 'fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-');
    static Folder = RAFDocumentTypeName.documentTypeConstant('Folder', 'Folder', 'Folders', "fas fa-folder-open content_state_warning_base");
    static Page = RAFDocumentTypeName.documentTypeConstant('Page', 'Page', 'Pages', 'far fa-file-invoice content_brand_tertiary_base');
    static Collection = RAFDocumentTypeName.documentTypeConstant('Collection', 'Collection', 'Collections', 'fas fa-books content_brand_primary_base');
}
export enum RAFBusinessAction {
    setValue = "setValue",
    clearValue = "clearValue",
    showField = "showField",
    showStep = "showStep",
    hideField = "hideField",
    hideStep = "hideStep",
    makeRequired = "makeRequired",
    showMessage = "showMessage",
    setCurrentDateAs = "setCurrentDateAs",
    setCurrentUserAs = "setCurrentUserAs",
    copyValue = "copyValue",
    sendMail = "sendMail",
    sendSms = "sendSms",
    alert = "alert",
    sendReport = "sendReport",
    createRecord = "createRecord",
    linkEmailToDeal = "linkEmailToDeal",
    createTicket = "createTicket",
    makeReadOnly = "makeReadOnly",
    showComments = "showComments",
    hideComments = "hideComments",
    showAttachments = "showAttachments",
    hideAttachments = "hideAttachments",
}

export enum RAFUserTeam {
    User = "User",
    Team = "Team",
}

// export class RAFButtonConstant {
//     static Save = { Id: 'Save', DisplayName: 'Save', IconCss: 'fas fa-floppy-disk' };
//     static SaveAsDraft = { Id: 'SaveAsDraft', DisplayName: 'Save As Draft', IconCss: 'fas fa-floppy-disk' };
//     static Create = { Id: 'Create', DisplayName: 'Create', IconCss: 'fas fa-plus' };
//     static Attach = { Id: 'Attach', DisplayName: 'Attach', IconCss: 'fas fa-paperclip' };
//     static Edit = { Id: 'Edit', DisplayName: 'Edit', IconCss: 'fas fa-pen-to-square' };
//     static Details = { Id: 'Details', DisplayName: 'Details', IconCss: 'fas fa-pen-to-square' };
//     static Resolve = { Id: 'Resolve', DisplayName: 'Resolve', IconCss: this.Edit.IconCss };
//     static Revise = { Id: 'Revise', DisplayName: 'Revise', IconCss: this.Edit.IconCss };
//     static MarkAsRead = { Id: 'MarkAsRead', DisplayName: 'Mark as Read', IconCss: 'fas fa-check-double' };
//     static Merge = { Id: 'Merge', DisplayName: 'Merge', IconCss: 'fas fa-merge' };
//     static Ok = { Id: 'Ok', DisplayName: 'Ok', IconCss: 'far fa-check' };
//     static Close = { Id: 'Close', DisplayName: 'Close', IconCss: 'fal fa-xmark' };
//     static Cancel = { Id: 'Cancel', DisplayName: 'Cancel', IconCss: 'fal fa-xmark' };
//     static Settings = { Id: 'Settings', DisplayName: 'Settings', IconCss: 'fas fa-gear' };
//     static Copy = { Id: 'Copy', DisplayName: 'Copy', IconCss: 'far fa-copy' };
//     static Preview = { Id: 'Preview', DisplayName: 'Preview', IconCss: 'fas fa-eye' };
//     static Complete = { Id: 'Complete', DisplayName: 'Complete', IconCss: 'far fa-check' };
//     static EditDashboard = { Id: 'EditDashboard', DisplayName: 'Edit Dashboard', IconCss: 'fa fa-th-large' };
//     static AddDashboard = { Id: 'AddDashboard', DisplayName: 'Add Dashboard', IconCss: 'fa fa-th-large' };
//     static AddWidget = { Id: 'AddWidget', DisplayName: 'Add Widget', IconCss: 'fas fa-plus' };
//     static Expand = { Id: 'Expand', DisplayName: 'Expand', IconCss: 'fas fa-expand-alt' };
//     static Add = { Id: 'Add', DisplayName: 'Add', IconCss: 'fas fa-plus' };
//     static AddTask = { Id: 'AddTask', DisplayName: 'Add Task', IconCss: 'fas fa-plus' };
//     static SyncOffline = { Id: 'SyncOffline', DisplayName: 'Sync Offline', IconCss: 'fas fa-rotate' };
//     static MassUpdate = { Id: 'MassUpdate', DisplayName: 'Mass Update', IconCss: 'far fa-file-pen' };
//     static Delete = { Id: 'Delete', DisplayName: 'Delete', IconCss: 'fas fa-trash' };
//     static VersionHistory = { Id: 'VersionHistory', DisplayName: 'Version History', IconCss: 'fa fa-history' };
//     static History = { Id: 'History', DisplayName: 'History', IconCss: 'fa fa-history' };
//     static Timeline = { Id: 'Timeline', DisplayName: 'Timeline', IconCss: 'fa fa-list-timeline' };
//     static More = { Id: 'More', DisplayName: 'More', IconCss: 'fas fa-ellipsis-vertical' };
//     static MoreHorizontal = { Id: 'More', DisplayName: 'More', IconCss: 'fas fa-ellipsis' };
//     static AllComments = { Id: 'AllComments', DisplayName: 'All Comments', IconCss: 'fal fa-comment-dots' };
//     static Switch = { Id: 'Switch', DisplayName: 'Switch', IconCss: 'fal fa-shuffle' };
//     static DialogClose = { Id: 'DialogClose', DisplayName: 'DialogClose', IconCss: 'fas fa-xmark' };
//     static Back = { Id: 'Back', DisplayName: 'Back', IconCss: 'fas fa-chevron-left' };
//     static Previous = { Id: 'Previous', DisplayName: 'Previous', IconCss: 'fas fa-chevron-left' };
//     static Next = { Id: 'Next', DisplayName: 'Next', IconCss: 'fas fa-chevron-right' };
//     static Download = { Id: 'Download', DisplayName: 'Download', IconCss: 'fa fa-cloud-download-alt' };
//     static Search = { Id: 'Search', DisplayName: 'Search', IconCss: 'fas fa-magnifying-glass' };
//     static Assign = { Id: 'Assign', DisplayName: 'Assign', IconCss: 'fas fa-user' };
//     static Sort = { Id: 'Sort', DisplayName: 'Sort', IconCss: 'fas fa-sort' };
//     static Filter = { Id: 'Filter', DisplayName: 'Filter', IconCss: 'far fa-filter' };
//     static ShowFilter = { Id: 'ShowFilter', DisplayName: 'Show Filter', IconCss: 'far fa-filter' };
//     static HideFilter = { Id: 'HideFilter', DisplayName: 'Hide Filter', IconCss: 'far fa-filter-slash' };
//     static Filters = { Id: 'Filters', DisplayName: 'Filters', IconCss: this.Filter.IconCss };
//     static AddCondition = { Id: 'AddCondition', DisplayName: 'Add Condition', IconCss: this.Add.IconCss };
//     static AllAttachments = { Id: 'AllAttachments', DisplayName: 'All Attachments', IconCss: this.Attach.IconCss };
//     static AddAction = { Id: 'AddAction', DisplayName: 'Add Action', IconCss: this.Add.IconCss };
//     static AddRule = { Id: 'AddRule', DisplayName: 'Add Rule', IconCss: this.Add.IconCss };
//     static Archive = { Id: 'Archive', DisplayName: 'Archive', IconCss: this.Cancel.IconCss };
//     static Actions = { Id: 'Actions', DisplayName: 'Actions', IconCss: 'fad fa-message-captions' };
//     static Configure = { Id: 'Configure', DisplayName: 'Configure', IconCss: this.Edit.IconCss };
//     static AddInput = { Id: 'AddInput', DisplayName: 'Add Input', IconCss: this.Add.IconCss };
//     static Clear = { Id: 'Clear', DisplayName: 'Clear', IconCss: this.Close.IconCss };
//     static Reset = { Id: 'Reset', DisplayName: 'Reset', IconCss: this.Clear.IconCss };
//     static AddView = { Id: 'AddView', DisplayName: 'Add View', IconCss: this.Add.IconCss };
//     static ShowSharedLibraries = { Id: 'ShowSharedLibraries', DisplayName: 'Show Shared Libraries', IconCss: 'fas fa-eye' };
//     static Desinger = { Id: 'Desinger', DisplayName: 'Desinger', IconCss: this.Edit.IconCss };
//     static Update = { Id: 'Update', DisplayName: 'Update', IconCss: this.Edit.IconCss };
//     static MarkAsDone = { Id: 'MarkAsDone', DisplayName: 'Mark As Done', IconCss: this.Complete.IconCss };
//     static RunningProcessComplete = { Id: 'MarkAsCompleted', DisplayName: 'Mark as Completed', IconCss: this.Complete.IconCss };
//     static Upload = { Id: 'Upload', DisplayName: 'Upload', IconCss: 'fas fa-upload' };
//     static UploadFile = { Id: 'UploadFile', DisplayName: 'Upload or Choose File', IconCss: 'fas fa-upload' };
//     static CompleteLater = { Id: 'CompleteLater', DisplayName: 'Complete Later', IconCss: 'fass fa-alarm-snooze' };
//     static SaveAsTemplate = { Id: 'SaveAsTemplate', DisplayName: 'Save As Template', IconCss: this.Save.IconCss };
//     static SaveAndPublish = { Id: 'SaveAndPublish', DisplayName: 'Save & Publish', IconCss: this.Upload.IconCss };
//     static Publish = { Id: 'Publish', DisplayName: 'Publish', IconCss: this.Upload.IconCss };
//     static Browse = { Id: 'Browse', DisplayName: 'Browse', IconCss: 'fas fa-magnifying-glass' };
//     static SaveDraft = { Id: 'SaveDraft', DisplayName: 'Save Draft', IconCss: this.Save.IconCss };
//     static AddRecord = { Id: 'AddRecord', DisplayName: 'Add Record', IconCss: this.Add.IconCss };
//     static Clone = { Id: 'Clone', DisplayName: 'Clone', IconCss: "far fa-clone" };
//     static Rename = { Id: 'Rename', DisplayName: 'Rename', IconCss: "fas fa-i-cursor" };
//     static Start = { Id: 'Start', DisplayName: 'Start', IconCss: "fas fa-play" };
//     static End = { Id: 'End', DisplayName: 'End', IconCss: "fas fa-play" };
//     static AddWorkspace = { Id: 'AddWorkspace', DisplayName: 'Add To Portal', IconCss: this.Add.IconCss };
//     static ToggleSideMenu = { Id: 'ToggleSideMenu', DisplayName: 'ToggleSideMenu', IconCss: this.Add.IconCss };
//     static SectionExpandBtn = { Id: 'SectionExpandBtn', DisplayName: 'Expand', IconCss: 'fas fa-expand-alt' };
//     static ShowMore = { Id: 'ShowMore', DisplayName: 'Show more', IconCss: 'fas fa-chevron-down' };
//     static HideLess = { Id: 'HideLess', DisplayName: 'Hide less', IconCss: 'fas fa-chevron-up' };
//     static Approve = { Id: 'Approve', DisplayName: 'Approve', IconCss: 'far fa-thumbs-up' };
//     static Invoice = { Id: 'Invoice', DisplayName: 'Invoice', IconCss: 'far fa-thumbs-up' };
//     static Reject = { Id: 'Reject', DisplayName: 'Reject', IconCss: 'far fa-thumbs-down' };
//     static Rotate = { Id: 'Rotate', DisplayName: 'Rotate', IconCss: 'fas fa-rotate-right' };
//     static ZoomIn = { Id: 'ZoomIn', DisplayName: 'Zoom In', IconCss: 'fas fa-magnifying-glass-plus' };
//     static ZoomOut = { Id: 'ZoomOut', DisplayName: 'Zoom Out', IconCss: 'fas fa-magnifying-glass-minus' };
// }
export class RAFButtonConstant {
    static buttonConstant(Id: string, DisplayName: string, IconCss: string) {
        return { Id, DisplayName, IconCss };
    }

    static Save = RAFButtonConstant.buttonConstant('Save', 'Save', 'fas fa-floppy-disk');
    static Submit = RAFButtonConstant.buttonConstant('Submit', 'Submit', 'fas fa-floppy-disk');
    static SaveAddNew = RAFButtonConstant.buttonConstant('SaveAddNew', 'Save & Add New', 'fas fa-floppy-disk');
    static SaveAndContinue = RAFButtonConstant.buttonConstant('SaveAndContinue', 'Save & Continue', 'fas fa-floppy-disk');
    static SaveAsDraft = RAFButtonConstant.buttonConstant('SaveAsDraft', 'Save As Draft', 'fas fa-floppy-disk');
    static Create = RAFButtonConstant.buttonConstant('Create', 'Create', 'fas fa-plus');
    static Attach = RAFButtonConstant.buttonConstant('Attach', 'Attach', 'fas fa-paperclip');
    static Edit = RAFButtonConstant.buttonConstant('Edit', 'Edit', 'fas fa-pen-to-square');
    static Details = RAFButtonConstant.buttonConstant('Details', 'Details', 'fas fa-file');
    static Resolve = RAFButtonConstant.buttonConstant('Resolve', 'Resolve', RAFButtonConstant.Edit.IconCss);
    static Revise = RAFButtonConstant.buttonConstant('Revise', 'Revise', RAFButtonConstant.Edit.IconCss);
    static Revert = RAFButtonConstant.buttonConstant('Revert', 'Revert', "fa fa-history");
    static MarkAsRead = RAFButtonConstant.buttonConstant('MarkAsRead', 'Mark as Read', 'fas fa-check-double');
    static MarkAsUnRead = RAFButtonConstant.buttonConstant('MarkAsUnRead', 'Mark as Unread', 'fas fa-circle');
    static Merge = RAFButtonConstant.buttonConstant('Merge', 'Merge', 'fas fa-merge');
    static Ok = RAFButtonConstant.buttonConstant('Ok', 'Ok', 'far fa-check');
    static Close = RAFButtonConstant.buttonConstant('Close', 'Close', 'fal fa-xmark');
    static Cancel = RAFButtonConstant.buttonConstant('Cancel', 'Cancel', 'fal fa-xmark');
    static Settings = RAFButtonConstant.buttonConstant('Settings', 'Settings', 'fas fa-gear');
    static Copy = RAFButtonConstant.buttonConstant('Copy', 'Copy', 'far fa-copy');
    static Preview = RAFButtonConstant.buttonConstant('Preview', 'Preview', 'fas fa-eye');
    static View = RAFButtonConstant.buttonConstant('View', 'View', 'fas fa-arrow-right-long');
    static Complete = RAFButtonConstant.buttonConstant('Complete', 'Complete', 'far fa-check');
    static ClockIn = RAFButtonConstant.buttonConstant('ClockIn', 'Clock In', 'far fa-check');
    static ClockOut = RAFButtonConstant.buttonConstant('ClockOut', 'Clock Out', 'far fa-check');
    static EditDashboard = RAFButtonConstant.buttonConstant('EditDashboard', 'Edit Dashboard', 'fa fa-th-large');
    static AddDashboard = RAFButtonConstant.buttonConstant('AddDashboard', 'Add Dashboard', 'fa fa-th-large');
    static AddWidget = RAFButtonConstant.buttonConstant('AddWidget', 'Add Widget', 'fas fa-plus');
    static Expand = RAFButtonConstant.buttonConstant('Expand', 'Expand', 'fas fa-expand-alt');
    static Add = RAFButtonConstant.buttonConstant('Add', 'Add', 'fas fa-plus');
    static AddTask = RAFButtonConstant.buttonConstant('AddTask', 'Add Task', 'fas fa-plus');
    static SyncOffline = RAFButtonConstant.buttonConstant('SyncOffline', 'Sync Offline', 'fas fa-rotate');
    static MassUpdate = RAFButtonConstant.buttonConstant('MassUpdate', 'Mass Update', 'far fa-file-pen');
    static Delete = RAFButtonConstant.buttonConstant('Delete', 'Delete', 'fas fa-trash');
    static VersionHistory = RAFButtonConstant.buttonConstant('VersionHistory', 'Version History', 'fa fa-history');
    static Version = RAFButtonConstant.buttonConstant('Version', 'Version', 'fa fa-history');
    static History = RAFButtonConstant.buttonConstant('History', 'History', 'fa fa-history');
    static Timeline = RAFButtonConstant.buttonConstant('Timeline', 'Timeline', 'fa fa-list-timeline');
    static More = RAFButtonConstant.buttonConstant('More', 'More', 'fas fa-ellipsis-vertical');
    static MoreHorizontal = RAFButtonConstant.buttonConstant('More', 'More', 'fas fa-ellipsis');
    static AllComments = RAFButtonConstant.buttonConstant('AllComments', 'All Comments', 'fal fa-comment-dots');
    static Switch = RAFButtonConstant.buttonConstant('Switch', 'Switch', 'fal fa-shuffle');
    static DialogClose = RAFButtonConstant.buttonConstant('DialogClose', 'DialogClose', 'fas fa-xmark');
    static ArrowBack = RAFButtonConstant.buttonConstant('Back', 'Back', 'fas fa-arrow-left-long');
    static Back = RAFButtonConstant.buttonConstant('Back', 'Back', 'fas fa-chevron-left');
    static Previous = RAFButtonConstant.buttonConstant('Previous', 'Previous', 'fas fa-chevron-left');
    static Next = RAFButtonConstant.buttonConstant('Next', 'Next', 'fas fa-chevron-right');
    static Download = RAFButtonConstant.buttonConstant('Download', 'Download', 'fas fa-down-to-bracket');
    static Import = RAFButtonConstant.buttonConstant('Import', 'Import', 'fas fa-upload');
    static Export = RAFButtonConstant.buttonConstant('Export', 'Export', 'fas fa-download');
    static Search = RAFButtonConstant.buttonConstant('Search', 'Search', 'fas fa-magnifying-glass');
    static Assign = RAFButtonConstant.buttonConstant('Assign', 'Assign', 'fas fa-user');
    static Sort = RAFButtonConstant.buttonConstant('Sort', 'Sort', 'fas fa-sort');
    static Filter = RAFButtonConstant.buttonConstant('Filter', 'Filter', 'far fa-filter');
    static ShowFilter = RAFButtonConstant.buttonConstant('ShowFilter', 'Show Filter', 'far fa-filter');
    static HideFilter = RAFButtonConstant.buttonConstant('HideFilter', 'Hide Filter', 'far fa-filter-slash');
    static Filters = RAFButtonConstant.buttonConstant('Filters', 'Filters', RAFButtonConstant.Filter.IconCss);
    static Select = RAFButtonConstant.buttonConstant('Select', 'Select', 'fal fa-square-check');
    static SelectAll = RAFButtonConstant.buttonConstant('SelectAll', 'Select All', 'fas fa-check-double');
    static UnSelect = RAFButtonConstant.buttonConstant('UnSelectAll', 'Unselect All', 'far fa-square');
    static AIAssistant = RAFButtonConstant.buttonConstant('AIAssistant', 'Ask FRED', 'far fa-wand-magic-sparkles');
    static Apply = RAFButtonConstant.buttonConstant('Apply', 'Apply', RAFButtonConstant.Filter.IconCss);
    static Generate = RAFButtonConstant.buttonConstant('Generate', 'Generate', RAFButtonConstant.Filter.IconCss);
    static ShowResults = RAFButtonConstant.buttonConstant('ShowResults', 'Show result(s)', RAFButtonConstant.Filter.IconCss);
    static AddCondition = RAFButtonConstant.buttonConstant('AddCondition', 'Add Condition', RAFButtonConstant.Add.IconCss);
    static AllAttachments = RAFButtonConstant.buttonConstant('AllAttachments', 'All Attachments', RAFButtonConstant.Attach.IconCss);
    static AddAction = RAFButtonConstant.buttonConstant('AddAction', 'Add Action', RAFButtonConstant.Add.IconCss);
    static AddRule = RAFButtonConstant.buttonConstant('AddRule', 'Add Rule', RAFButtonConstant.Add.IconCss);
    static Archive = RAFButtonConstant.buttonConstant('Archive', 'Archive', "fa fa-archive");
    static Actions = RAFButtonConstant.buttonConstant('Actions', 'Actions', 'fad fa-message-captions');
    static Configure = RAFButtonConstant.buttonConstant('Configure', 'Configure', this.Edit.IconCss);
    static AddInput = RAFButtonConstant.buttonConstant('AddInput', 'Add Input', this.Add.IconCss);
    static Clear = RAFButtonConstant.buttonConstant('Clear', 'Clear', this.Close.IconCss);
    static Reset = RAFButtonConstant.buttonConstant('Reset', 'Reset', this.Clear.IconCss);
    static AddView = RAFButtonConstant.buttonConstant('AddView', 'Add View', this.Add.IconCss);
    static ShowSharedLibraries = RAFButtonConstant.buttonConstant('ShowSharedLibraries', 'Show Shared Libraries', 'fas fa-eye');
    static Desinger = RAFButtonConstant.buttonConstant('Desinger', 'Desinger', this.Edit.IconCss);
    static Update = RAFButtonConstant.buttonConstant('Update', 'Update', this.Edit.IconCss);
    static MarkAsDone = RAFButtonConstant.buttonConstant('MarkAsDone', 'Mark As Done', this.Complete.IconCss);
    static RunningProcessComplete = RAFButtonConstant.buttonConstant('MarkAsCompleted', 'Mark as Completed', this.Complete.IconCss);
    static Upload = RAFButtonConstant.buttonConstant('Upload', 'Upload', 'fas fa-upload');
    static Reupload = RAFButtonConstant.buttonConstant('Re-upload', 'Re-upload', 'fa fa-history');
    static UploadFile = RAFButtonConstant.buttonConstant('UploadFile', 'Upload or Choose File', 'fas fa-upload');
    static CompleteLater = RAFButtonConstant.buttonConstant('CompleteLater', 'Complete Later', 'fass fa-alarm-snooze');
    static SaveAsTemplate = RAFButtonConstant.buttonConstant('SaveAsTemplate', 'Save As Template', this.Save.IconCss);
    static SaveAndPublish = RAFButtonConstant.buttonConstant('SaveAndPublish', 'Save & Publish', this.Upload.IconCss);
    static SwitchToDraft = RAFButtonConstant.buttonConstant('SwitchToDraft', 'Switch to draft', this.Export.IconCss);
    static Publish = RAFButtonConstant.buttonConstant('Publish', 'Publish', this.Upload.IconCss);
    static Browse = RAFButtonConstant.buttonConstant('Browse', 'Browse', 'fas fa-magnifying-glass');
    static SaveDraft = RAFButtonConstant.buttonConstant('SaveDraft', 'Save Draft', this.Save.IconCss);
    static Skip = RAFButtonConstant.buttonConstant('Skip', 'Skip', this.Save.IconCss);
    static AddRecord = RAFButtonConstant.buttonConstant('AddRecord', 'Add Record', this.Add.IconCss);
    static Clone = RAFButtonConstant.buttonConstant('Clone', 'Clone', "far fa-clone");
    static Rename = RAFButtonConstant.buttonConstant('Rename', 'Rename', "fas fa-i-cursor");
    static Start = RAFButtonConstant.buttonConstant('Start', 'Start', "fas fa-play");
    static End = RAFButtonConstant.buttonConstant('End', 'End', "fas fa-play");
    static AddWorkspace = RAFButtonConstant.buttonConstant('AddWorkspace', 'Add To Portal', this.Add.IconCss);
    static ToggleSideMenu = RAFButtonConstant.buttonConstant('ToggleSideMenu', 'ToggleSideMenu', this.Add.IconCss);
    static SectionExpandBtn = RAFButtonConstant.buttonConstant('SectionExpandBtn', 'Expand', 'fas fa-expand-alt');
    static ShowMore = RAFButtonConstant.buttonConstant('ShowMore', 'Show more', 'fas fa-chevron-down');
    static HideLess = RAFButtonConstant.buttonConstant('HideLess', 'Hide less', 'fas fa-chevron-up');
    static Approve = RAFButtonConstant.buttonConstant('Approve', 'Approve', 'far fa-thumbs-up');
    static Invoice = RAFButtonConstant.buttonConstant('Invoice', 'Invoice', 'far fa-thumbs-up');
    static Reject = RAFButtonConstant.buttonConstant('Reject', 'Reject', 'far fa-thumbs-down');
    static Paid = RAFButtonConstant.buttonConstant('Paid', 'Paid', 'far fa-thumbs-up');
    static Rotate = RAFButtonConstant.buttonConstant('Rotate', 'Rotate', 'fas fa-rotate-right');
    static ZoomIn = RAFButtonConstant.buttonConstant('ZoomIn', 'Zoom In', 'fas fa-magnifying-glass-plus');
    static ZoomOut = RAFButtonConstant.buttonConstant('ZoomOut', 'Zoom Out', 'fas fa-magnifying-glass-minus');
    static ManageViews = RAFButtonConstant.buttonConstant('ManageViews', 'Manage Views', 'far fa-gallery-thumbnails');
    static ManageTableViews = RAFButtonConstant.buttonConstant('ManageTableViews', 'Table', 'e-icons e-table table-view-color');
    static ManageGroupViews = RAFButtonConstant.buttonConstant('ManageGroupViews', 'Group', 'e-icons e-date-occurring group-view-color');
    static ManagePivotViews = RAFButtonConstant.buttonConstant('ManagePivotViews', 'Pivot', 'e-icons e-chart-switch-row-column pivot-view-color');
    static ManageChartViews = RAFButtonConstant.buttonConstant('ManageChartViews', 'Chart', 'fa fa-chart-pie chart-view-color');
    static ManageDashboardViews = RAFButtonConstant.buttonConstant('ManageDashboardViews', 'Dashboard', 'fa fa-th-large dashboard-view-color');
    static ManageReportTableViews = RAFButtonConstant.buttonConstant('ManageReportTableViews', 'Report Table', 'fa fa-th-large group-view-color');
    static ManageMetricViews = RAFButtonConstant.buttonConstant('ManageMetricViews', 'Metric', 'fa fa-th-large group-view-color');
    static Unlink = RAFButtonConstant.buttonConstant('Unlink', 'Unlink', 'fa fa-unlink');
    static Sign = RAFButtonConstant.buttonConstant('Sign', 'Sign', 'far fa-file-signature');
    static AcknowledgementRequest = RAFButtonConstant.buttonConstant('AcknowledgementRequest', 'Request', 'fal fa-signature');
    static XeroSyncLog = RAFButtonConstant.buttonConstant('XeroSyncLog', 'Xero Sync Log', 'XeroSyncLog');
    static SendEmail = RAFButtonConstant.buttonConstant('SendEmail', 'Send Email', 'fal fa-paper-plane');
}



export const getDocumentTypeDisplayName = (type: string) => {
    let documentType;
    if (type === RAFDocumentType.File) {
        documentType = RAFDocumentTypeDisplayname.File;
    }
    else if (type === RAFDocumentType.Folder) {
        documentType = RAFDocumentTypeDisplayname.Folder;
        documentType = RAFDocumentTypeDisplayname.Folder;
    }
    else if (type === RAFDocumentType.Page) {
        documentType = RAFDocumentTypeDisplayname.Page;
    }
    return documentType;

};

export enum RAFGridView {
    TableView = 'TableView',
    ListView = 'ListView',
    CalendarView = 'Calendar',
    WeekView = 'WeekView',
    MonthView = 'MonthView',
    TimeSheetView = 'Timesheet',
}
export enum RAFGridViewDisplayname {
    TableView = 'Table',
    ListView = 'List',
    ActivityView = 'Activity view',
    CalendarView = 'Calendar',
    TimeSheetView = 'Timesheet',
}
export enum RAFGridViewIconCss {
    TableView = 'far fa-table',
    ListView = 'fad fa-list-ul',
    CalendarView = 'fas fa-calendar-days',
}
export const rafNoViewName = 'No view found';

//export const SuperAdministrator = 'Super Administrator';
export const ESIOAppAdministrator = 'ESIO App Administrator';
export const ESIOSuperAdministrator = 'ESIO Super Administrator';

export const BrowserIsDevice = Browser.isDevice; // === true for not system view (mobile, tablet)
export const BrowserWidth = window.innerWidth;
export const LineSeparatorClass = 'raf_line_separator fat fa-pipe';

export enum RAFRiskRegisterType {
    Risk = "Risk",
    HealthCondition = "Health Condition"
}

export class RAFFilePreSignedURL {
    EntityId?: string;
    ObjectName?: string;
    UploadURL?: string;
    DocumentType?: "File" | "Thumbnail";
    MimeType?: string;
    Error?: string;
    CustomData?: string;
    FileSize?: number;
    QuestionName?: string;
}