const CareRecipient_Read = "care_recipient::Read";
const CareRecipient_Manage = "care_recipient::Manage";
const CareRecipient_Delete = "care_recipient::Delete";
const CareRecipient_ManageLogin = "care_recipient::ManageLogin";

//temp
const CareRecipient_Planner = "care_recipient::Planner";
const CareRecipient_Feeds = "care_recipient::Feeds";
//temp

const CareRecipient_ViewClientHistory = "care_recipient::ViewClientHistory";
const CareRecipient_ViewServiceAgreements =
  "care_recipient::ViewServiceAgreements";
const CareRecipient_ManageServiceAgreements =
  "care_recipient::ManageServiceAgreements";
const CareRecipient_DeleteServiceAgreements =
  "care_recipient::DeleteServiceAgreements";

const CareRecipient_ViewServiceContractItems =
  "care_recipient::ViewServiceContractItems";
const CareRecipient_ManageServiceContractItems =
  "care_recipient::ManageServiceContractItems";
const CareRecipient_DeleteServiceContractItems =
  "care_recipient::DeleteServiceContractItems";

const CareRecipient_ViewDocuments = "care_recipient::ViewDocuments";
const CareRecipient_ManageDocuments = "care_recipient::ManageDocuments";
const CareRecipient_DeleteDocuments = "care_recipient::DeleteDocuments";
const CareRecipient_ManageCarePlan = "care_recipient::ManageCarePlan";
const CareRecipient_ReadNotes = "care_recipient::ReadNotes";
const CareRecipient_ManageMyNotes = "care_recipient::ManageMyNotes";
const CareRecipient_ManageAllNotes = "care_recipient::ManageAllNotes";
const CareRecipient_EditDocumentProperties =
  "care_recipient::EditDocumentProperties";
const CareRecipient_EditDocumentExpiryDate =
  "care_recipient::EditDocumentExpiryDate";

const CareRecipient_ViewCompliance = "care_recipient::ViewCompliance";
const CareRecipient_ManageCompliance = "care_recipient::ManageCompliance";
const CareRecipient_UploadComplianceDocument =
  "care_recipient::UploadComplianceDocument";
const CareRecipient_ApproveComplianceDocument =
  "care_recipient::ApproveComplianceDocument";
const CareRecipient_CommentComplianceDocument =
  "care_recipient::CommentComplianceDocument";

const CareRecipient_ReadWorkflows = "care_recipient::ReadWorkflows";
const CareRecipient_ManageWorkflows = "care_recipient::ManageWorkflows";
const CareRecipient_ReadForms = "care_recipient::ReadForms";
const CareRecipient_ManageForms = "care_recipient::ManageForms";

const ServiceTransaction_MyShifts_Read = "service_transaction_myshifts::Read";
const ServiceTransaction_MyShifts_EnableLocation =
  "service_transaction_myshifts::EnableLocation";
const ServiceTransaction_MyShifts_EnableActivities =
  "service_transaction_myshifts::EnableActivities";
const ServiceTransaction_MyShifts_EnableForms =
  "service_transaction_myshifts::EnableForms";
const ServiceTransaction_MyShifts_RequestTimeChange =
  "service_transaction_myshifts::RequestTimeChange";
const ServiceTransaction_MyShifts_TeamShiftActions =
  "service_transaction_myshifts::TeamShiftActions";

const ServiceTransaction_Read = "service_transaction::ReadRoster";
const ServiceTransaction_ManageShifts = "service_transaction::ManageShifts";
const ServiceTransaction_AddMultipleShifts =
  "service_transaction::AddMultipleShifts";
const ServiceTransaction_DeleteShiftsBeforeStart =
  "service_transaction::DeleteShiftsBeforeStart";
const ServiceTransaction_DeleteShiftsAfterStart =
  "service_transaction::DeleteShiftsAfterStart";
const ServiceTransaction_ManageShiftTemplate =
  "service_transaction::ManageShiftTemplate";
const ServiceTransaction_ApproveTimeChange =
  "service_transaction::ApproveTimeChange";
const ServiceTransaction_ViewShiftHistory =
  "service_transaction::ViewShiftHistory";
const ServiceTransaction_ViewShiftOffer = "service_transaction::ViewShiftOffer";
const ServiceTransaction_SendShiftOffer = "service_transaction::SendShiftOffer";
const ServiceTransaction_ApproveShiftOffer =
  "service_transaction::ApproveShiftOffer";

const ServiceTransaction_ReadBookings = "service_transaction::ReadBookings";
const ServiceTransaction_ManageBookings = "service_transaction::ManageBookings";

const ServiceTransaction_SupportLog_ViewSupportLog =
  "service_transaction_supportlog::ViewSupportLog";
const ServiceTransaction_SupportLog_SummarizeSupportLog =
  "service_transaction_supportlog::SummarizeSupportLog";
const ServiceTransaction_SupportLog_GeneratePDF =
  "service_transaction_supportlog::GeneratePDF";

const ServiceTransactionSupportLog_PreviousLog =
  "service_transaction_supportlog::PreviousLog";

const InvoiceTransaction_ViewBilling = "invoice_transaction::ViewBilling";
const InvoiceTransaction_ManageBilling = "invoice_transaction::ManageBilling";

const PayrollTransaction_ViewTimeSheet = "payroll_transaction::ViewTimeSheet";
const PayrollTransaction_ManageTimeSheet =
  "payroll_transaction::ManageTimeSheet";

const CarePlan_Read = "care_plan::Read";
const CarePlan_Add = "care_plan::Add";
const CarePlan_Update = "care_plan::Update";
const CarePlan_Delete = "care_plan::Delete";

const CareGoal_Read = "care_goal::Read";
const CareGoal_Manage = "care_goal::Manage";
//const CareGoal_Update = "care_goal::Update";
//const CareGoal_Delete = "care_goal::Delete";

const CareAssessment_Read = "assessment::Read";
const CareAssessment_Manage = "assessment::Manage";

const CareInterventionSupport_Read = "care_intervention_support::Read";
const CareInterventionSupport_Manage = "care_intervention_support::Manage";
//const CareInterventionSupport_Update = "care_intervention_support::Update";
//const CareInterventionSupport_Delete = "care_intervention_support::Delete";

const CareServiceContractItem_Read = "service_contract_item::Read";
const CareServiceContractItem_Add = "service_contract_item::Add";
const CareServiceContractItem_Update = "service_contract_item::Update";
const CareServiceContractItem_Delete = "service_contract_item::Delete";

const CareTeam_Read = "care_team::Read";
const CareTeam_Manage = "care_team::Manage";

const Employee_Read = "employee::Read";
const Employee_Manage = "employee::Manage";
const Employee_Delete = "employee::Delete";
const Employee_Customize = "employee::Customize";
const Employee_Export = "employee::Export";
const Employee_Import = "employee::Import";
const Employee_ViewDocuments = "employee::ViewDocuments";
const Employee_DeleteDocuments = "employee::DeleteDocuments";
const Employee_ManageDocuments = "employee::ManageDocuments";
const Employee_EditDocumentProperties = "employee::EditDocumentProperties";
const Employee_EditDocumentExpiryDate = "employee::EditDocumentExpiryDate";
const Employee_ManageLogin = "employee::ManageLogin";
const Employee_LinkXero = "employee::LinkXero";

const EmployeeLeave_ApproveOrReject = "employeeLeave::ApproveOrReject";
const EmployeeLeave_ApplyLeaveRequest = "employeeLeave::ApplyLeaveRequest";

const EmployeeAvailability_AddFixedAvailability =
  "employeeAvailability::AddFixedAvailability";
const EmployeeAvailability_AddSpecificAvailability =
  "employeeAvailability::AddSpecificAvailability";

const Employee_ReadWorkflows = "employee::ReadWorkflows";
const Employee_ManageWorkflows = "employee::ManageWorkflows";

const Employee_ViewCompliance = "employee::ViewCompliance";
const Employee_ManageCompliance = "employee::ManageCompliance";
const Employee_UploadComplianceDocument = "employee::UploadComplianceDocument";
const Employee_ApproveComplianceDocument =
  "employee::ApproveComplianceDocument";
const Employee_CommentComplianceDocument =
  "employee::CommentComplianceDocument";

const XeroSync_ViewTimeSheet = "XeroSync::ViewTimeSheet";
const XeroSync_SyncTimesheetsToXero = "XeroSync::SyncTimesheetsToXero";

const RiskRegister_Read = "risk_register::Read";
const RiskRegister_Add = "risk_register::Add";
const RiskRegister_Update = "risk_register::Update";
const RiskRegister_Delete = "risk_register::Delete";

const CareStandardizedLibrary_Read = "standardized_library::Read";
const CareStandardizedLibrary_Add = "standardized_library::Add";
const CareStandardizedLibrary_Update = "standardized_library::Update";
const CareStandardizedLibrary_Delete = "standardized_library::Delete";
const CareStandardizedLibrary_Customize = "standardized_library::Customize";
const CareStandardizedLibrary_Export = "standardized_library::Export";

const CareRecipientPreference_Read = "care_recipient_preference::Read";
const CareRecipientPreference_Add = "care_recipient_preference::Add";
const CareRecipientPreference_Update = "care_recipient_preference::Update";
const CareRecipientPreference_Delete = "care_recipient_preference::Delete";

const CareRecipientTrigger_Read = "care_recipient_trigger::Read";
const CareRecipientTrigger_Add = "care_recipient_trigger::Add";
const CareRecipientTrigger_Update = "care_recipient_trigger::Update";
const CareRecipientTrigger_Delete = "care_recipient_trigger::Delete";

const CareMedication_Read = "care_medication::Read";
const CareMedication_Add = "care_medication::Add";
const CareMedication_Update = "care_medication::Update";
const CareMedication_Delete = "care_medication::Delete";

const CareShiftLog_Read = "care_shift_log::Read";
const CareShiftLog_Add = "care_shift_log::Add";
const CareShiftLog_Update = "care_shift_log::Update";
const CareShiftLog_Delete = "care_shift_log::Delete";

const ChecklistItemTransAdminComplianceDocuments =
  "checklist_item_trans::AdminComplianceDocuments";

const QualitySafetyView = "QualitySafety::View";

export const CareShiftLogPermissionConstants = {
  CareShiftLogRead: CareShiftLog_Read,
  CareShiftLogAdd: CareShiftLog_Add,
  CareShiftLogUpdate: CareShiftLog_Update,
  CareShiftLogDelete: CareShiftLog_Delete,
};

export const CareMedicationPermissionConstants = {
  CareMedicationRead: CareMedication_Read,
  CareMedicationAdd: CareMedication_Add,
  CareMedicationUpdate: CareMedication_Update,
  CareMedicationDelete: CareMedication_Delete,
};

export const CareRecipientTriggerPermissionConstants = {
  CareRecipientTriggerRead: CareRecipientTrigger_Read,
  CareRecipientTriggerAdd: CareRecipientTrigger_Add,
  CareRecipientTriggerUpdate: CareRecipientTrigger_Update,
  CareRecipientTriggerDelete: CareRecipientTrigger_Delete,
};

export const CareRecipientPreferencePermissionConstants = {
  CareRecipientPreferenceRead: CareRecipientPreference_Read,
  CareRecipientPreferenceAdd: CareRecipientPreference_Add,
  CareRecipientPreferenceUpdate: CareRecipientPreference_Update,
  CareRecipientPreferenceDelete: CareRecipientPreference_Delete,
};

export const CareStandardizedLibraryConstants = {
  CareStandardizedLibraryRead: CareStandardizedLibrary_Read,
  CareStandardizedLibraryAdd: CareStandardizedLibrary_Add,
  CareStandardizedLibraryUpdate: CareStandardizedLibrary_Update,
  CareStandardizedLibraryDelete: CareStandardizedLibrary_Delete,
  CareStandardizedLibraryCustomize: CareStandardizedLibrary_Customize,
  CareStandardizedLibraryExport: CareStandardizedLibrary_Export,
};

export const RiskRegisterPermissionConstants = {
  RiskRegisterRead: RiskRegister_Read,
  RiskRegisterAdd: RiskRegister_Add,
  RiskRegisterUpdate: RiskRegister_Update,
  RiskRegisterDelete: RiskRegister_Delete,
};

export const CareEmployeePermissionConstants = {
  EmployeeRead: Employee_Read,
  EmployeeManage: Employee_Manage,
  EmployeeDelete: Employee_Delete,
  EmployeeCustomize: Employee_Customize,
  EmployeeExport: Employee_Export,
  EmployeeImport: Employee_Import,
  EmployeeViewDocuments: Employee_ViewDocuments,
  EmployeeDeleteDocuments: Employee_DeleteDocuments,
  EmployeeManageDocuments: Employee_ManageDocuments,
  EmployeeEditDocumentProperties: Employee_EditDocumentProperties,
  EmployeeEditDocumentExpiryDate: Employee_EditDocumentExpiryDate,
  EmployeeManageLogin: Employee_ManageLogin,
  EmployeeLinkXero: Employee_LinkXero,
  EmployeeLeaveApproveOrReject: EmployeeLeave_ApproveOrReject,
  EmployeeLeaveApplyLeaveRequest: EmployeeLeave_ApplyLeaveRequest,
  EmployeeAvailabilityAddFixedAvailability:
    EmployeeAvailability_AddFixedAvailability,
  EmployeeAvailabilityAddSpecificAvailability:
    EmployeeAvailability_AddSpecificAvailability,
  EmployeeReadWorkflows: Employee_ReadWorkflows,
  EmployeeManageWorkflows: Employee_ManageWorkflows,
  EmployeeViewCompliance: Employee_ViewCompliance,
  EmployeeManageCompliance: Employee_ManageCompliance,
  EmployeeUploadComplianceDocument: Employee_UploadComplianceDocument,
  EmployeeApproveComplianceDocument: Employee_ApproveComplianceDocument,
  EmployeeCommentComplianceDocument: Employee_CommentComplianceDocument,
};

export const XeroSyncPermissionConstants = {
  XeroSyncViewTimeSheet: XeroSync_ViewTimeSheet,
  XeroSyncSyncTimesheetsToXero: XeroSync_SyncTimesheetsToXero,
};

export const CareRecipientPermissionConstants = {
  CareRecipientRead: CareRecipient_Read,
  CareRecipientManage: CareRecipient_Manage,
  CareRecipientDelete: CareRecipient_Delete,
  CareRecipient_ManageLogin: CareRecipient_ManageLogin,

  //temp
  CareRecipientPlanner: CareRecipient_Planner,
  CareRecipientFeeds: CareRecipient_Feeds,
  //temp

  CareRecipientViewClientHistory: CareRecipient_ViewClientHistory,
  CareRecipientViewServiceAgreements: CareRecipient_ViewServiceAgreements,
  CareRecipientManageServiceAgreements: CareRecipient_ManageServiceAgreements,
  CareRecipientDeleteServiceAgreements: CareRecipient_DeleteServiceAgreements,

  CareRecipientViewServiceContractItems: CareRecipient_ViewServiceContractItems,
  CareRecipientManageServiceContractItems:
    CareRecipient_ManageServiceContractItems,
  CareRecipientDeleteServiceContractItems:
    CareRecipient_DeleteServiceContractItems,

  CareRecipientViewDocuments: CareRecipient_ViewDocuments,
  CareRecipientManageCarePlan: CareRecipient_ManageCarePlan,
  CareRecipientReadNotes: CareRecipient_ReadNotes,
  CareRecipientManageMyNotes: CareRecipient_ManageMyNotes,
  CareRecipientManageAllNotes: CareRecipient_ManageAllNotes,
  CareRecipientDeleteDocuments: CareRecipient_DeleteDocuments,
  CareRecipientManageDocuments: CareRecipient_ManageDocuments,
  CareRecipientEditDocumentProperties: CareRecipient_EditDocumentProperties,
  CareRecipientEditDocumentExpiryDate: CareRecipient_EditDocumentExpiryDate,
  CareRecipientViewCompliance: CareRecipient_ViewCompliance,
  CareRecipientManageCompliance: CareRecipient_ManageCompliance,
  CareRecipientUploadComplianceDocument: CareRecipient_UploadComplianceDocument,
  CareRecipientApproveComplianceDocument:
    CareRecipient_ApproveComplianceDocument,
  CareRecipientCommentComplianceDocument:
    CareRecipient_CommentComplianceDocument,
  CareRecipientReadWorkflows: CareRecipient_ReadWorkflows,
  CareRecipientManageWorkflows: CareRecipient_ManageWorkflows,
  CareRecipientReadForms: CareRecipient_ReadForms,
  CareRecipientManageForms: CareRecipient_ManageForms,
};

export const ServiceTransactionPermissionConstants = {
  ServiceTransactionMyShifts: ServiceTransaction_MyShifts_Read,
  ServiceTransactionMyShiftsEnableLocation:
    ServiceTransaction_MyShifts_EnableLocation,
  ServiceTransactionMyShiftsEnableActivities:
    ServiceTransaction_MyShifts_EnableActivities,
  ServiceTransactionMyShiftsEnableForms:
    ServiceTransaction_MyShifts_EnableForms,
  ServiceTransactionMyShiftsRequestTimeChange:
    ServiceTransaction_MyShifts_RequestTimeChange,
  ServiceTransactionMyShiftsTeamShiftActions:
    ServiceTransaction_MyShifts_TeamShiftActions,

  ServiceTransactionRead: ServiceTransaction_Read,
  ServiceTransactionManageShifts: ServiceTransaction_ManageShifts,
  ServiceTransactionAddMultipleShifts: ServiceTransaction_AddMultipleShifts,
  ServiceTransactionDeleteShiftsBeforeStart:
    ServiceTransaction_DeleteShiftsBeforeStart,
  ServiceTransactionDeleteShiftsAfterStart:
    ServiceTransaction_DeleteShiftsAfterStart,
  ServiceTransactionManageShiftTemplate: ServiceTransaction_ManageShiftTemplate,
  ServiceTransactionApproveTimeChange: ServiceTransaction_ApproveTimeChange,
  ServiceTransactionViewShiftHistory: ServiceTransaction_ViewShiftHistory,
  ServiceTransactionViewShiftOffer: ServiceTransaction_ViewShiftOffer,
  ServiceTransactionSendShiftOffer: ServiceTransaction_SendShiftOffer,
  ServiceTransactionApproveShiftOffer: ServiceTransaction_ApproveShiftOffer,
  ServiceTransactionReadBookings: ServiceTransaction_ReadBookings,
  ServiceTransactionManageBookings: ServiceTransaction_ManageBookings,
};

export const ServiceTransactionSupportLogPermissionConstants = {
  ServiceTransactionSupportLogViewSupportLog:
    ServiceTransaction_SupportLog_ViewSupportLog,
  ServiceTransactionSupportLogSummarizeSupportLog:
    ServiceTransaction_SupportLog_SummarizeSupportLog,
  ServiceTransactionSupportLogGeneratePDF:
    ServiceTransaction_SupportLog_GeneratePDF,
};

export const ServiceTransactionSupportLogPreviousLogPermissionConstants = {
  ServiceTransactionSupportLogPreviousLog:
    ServiceTransactionSupportLog_PreviousLog,
};

export const InvoiceTransactionPermissionConstants = {
  InvoiceTransactionViewBilling: InvoiceTransaction_ViewBilling,
  InvoiceTransactionManageBilling: InvoiceTransaction_ManageBilling,
};

export const PayrollTransactionPermissionConstants = {
  PayrollTransactionViewTimeSheet: PayrollTransaction_ViewTimeSheet,
  PayrollTransactionManageTimeSheet: PayrollTransaction_ManageTimeSheet,
};

export const CarePlanPermissionConstants = {
  CarePlanRead: CarePlan_Read,
  CarePlanAdd: CarePlan_Add,
  CarePlanUpdate: CarePlan_Update,
  CarePlanDelete: CarePlan_Delete,
};

export const CareGoalPermissionConstants = {
  CareGoalRead: CareGoal_Read,
  CareGoalManage: CareGoal_Manage,
  //CareGoalUpdate: CareGoal_Update,
  //CareGoalDelete: CareGoal_Delete
};

export const CareAssessmentPermissionConstants = {
  CareAssessmentRead: CareAssessment_Read,
  CareAssessmentManage: CareAssessment_Manage,
  //CareAssessmentUpdate: CareAssessment_Update,
  //CareAssessmentDelete: CareAssessment_Delete
};

export const CareInterventionSupportPermissionConstants = {
  CareInterventionSupportRead: CareInterventionSupport_Read,
  CareInterventionSupportManage: CareInterventionSupport_Manage,
  //CareInterventionSupportUpdate: CareInterventionSupport_Update,
  //CareInterventionSupportDelete: CareInterventionSupport_Delete
};

// export const CareServiceContractItemPermissionConstants = {
//   CareServiceContractItemRead: CareServiceContractItem_Read,
//   CareServiceContractItemAdd: CareServiceContractItem_Add,
//   CareServiceContractItemUpdate: CareServiceContractItem_Update,
//   CareServiceContractItemDelete: CareServiceContractItem_Delete,
// };

//const CareCQIRegister_Read = "cqi_register::Read";
// export const CareCQIRegisterPermissionConstants = { permission not available in permission json
//     CareCQIRegisterRead: CareCQIRegister_Read
// };

export const CareTeamPermissionConstants = {
  CareTeamRead: CareTeam_Read,
  CareTeamManage: CareTeam_Manage,
};

export const ChecklistItemTransPermissionConstants = {
  ChecklistItemTransAdminComplianceDocuments:
    ChecklistItemTransAdminComplianceDocuments,
};

export const QualitySafetyPermissionConstants = {
  QualitySafetyView: QualitySafetyView,
};
