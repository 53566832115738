export enum MenuCategory {
    Default = 'Default',
    AdminSettings = 'AdminSettings',
    UserSettings = 'UserSettings',
    SystemSettings = 'SystemSettings',
    ContentManagement = 'ContentManagement',
    ESIOBuilder = 'ESIOBuilder',
}

export enum MenuCategoryDisplayName {
    Default = 'Default',
    AdminSettings = 'Administration',
    SystemSettings = 'System Settings',
    UserSettings = 'User Settings',
    ESIOBuilder = 'ESIO Builder',
}