import { Browser } from '@syncfusion/ej2-base';
import { NavigateFunction } from 'react-router-dom';
import { msalInstance } from "..";
import { RAFDataResult, RAFSFGridService } from "../RAFComponents/Grid/RAFDataList";
import { RAFCustomFilter } from "../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { getAllUserGroups, getAllUsers, getSessionStorage, setImpersonateUserDefinition, setSessionStorage } from "../RAFComponents/helpers/AppHelper";
import { getURLNameByModuleName } from "../RAFComponents/helpers/RAFMenuHelper";
import { RAFSort } from "../RAFComponents/helpers/types";
import { IsNullOrWhiteSpace, getSaveRequest, isNotNullAndUndefined } from "../RAFComponents/helpers/utils";
import { ClientInfoRow } from '../RAFComponents/models/Common/ClientInfoRow';
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
import { LookUpGroupRow } from '../RAFComponents/models/CompositeTypes/LookUpGroupRow';
import { LogInAsRequestRow } from '../RAFModules/ActiveContacts/User/LogInAsRequestRow';
import { UserGroupRow } from "../RAFModules/ActiveContacts/UserGroup/UserGroupRow";
import { getPortalViewByViewName } from '../RAFModules/Common/Workspace/WorkspaceHelper';
import { WorkspaceRow } from "../RAFModules/Common/Workspace/WorkspaceRow";
import { Constants, ContentType, RAFUserTeam, RAFViewType, StorageKey } from "../constants/Common/Constants";
import { RAFEntityName } from '../constants/Common/EntityConstants';
import { RMStorageKey } from '../constants/Common/RMConstants';
import { MenuCategory } from '../constants/MenuConstant/RAFMenuCategory';
import { FocusRow, RAFFocusUserType } from "../pages/FocusRow";
import * as repositoryActions from "./../RAFComponents/store/actions/repositoryActions";

export function toggleSideMenu() {

    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    const windowWidth = document.querySelector("body").offsetWidth;
    if (transparentBackdrop !== null && windowWidth < 769) { transparentBackdrop.classList.toggle("show"); };

    const sidenav = document.querySelector(".sidebar-nav");
    if (sidenav !== null) {
        if (Browser.isDevice) {
            sidenav.classList.toggle("isDevice");
        }
        sidenav.classList.toggle("mini-navbar");
    };

    const sidenavMenu = document.querySelector(".rpm-mp-menu");
    if (sidenavMenu !== null) { sidenavMenu.classList.toggle("mini-navbar"); };

    //const closeSideMenu = document.querySelector(".closeSideMenu");
    //if (closeSideMenu !== null) { closeSideMenu.classList.toggle("mini-navbar") };

    const listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader !== null) { listPageHeader.classList.toggle("mini-navbar"); };

    const listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent !== null) { listPageContent.classList.toggle("mini-navbar"); };

    const detailsPageContent = document.querySelector(".detailsRightSection");
    if (detailsPageContent !== null) { detailsPageContent.classList.toggle("mini-navbar"); };

    const header = document.querySelector(".page-heading");
    if (header !== null) { header.classList.toggle("mini-navbar"); }

    const scrollspyItems = document.querySelector(".scrollspyItems");
    if (scrollspyItems !== null) { scrollspyItems.classList.toggle("mini-navbar"); }

    const dealDetailsPage = document.querySelector(".dealDetailsPage");
    if (dealDetailsPage !== null) { dealDetailsPage.classList.toggle("mini-navbar"); }

    const topNavbar = document.querySelector(".topNavBar ");
    if (topNavbar !== null) { topNavbar.classList.toggle("mini-navbar"); }

    const topNavMenu = document.querySelector(".topNavMenu ");
    if (topNavMenu !== null) { topNavMenu.classList.toggle("mini-navbar"); }

    const topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile !== null) { topNavMenuProfile.classList.toggle("mini-navbar"); }

    const gridHeader = document.querySelector(".e-gridheader");
    if (gridHeader !== null) { gridHeader.classList.toggle("mini-navbar"); }

}
export function changeGridContentHeight() {
    let eFilterbar = document.querySelector('.e-filterbar');
    let eGridpager = document.querySelector('.e-gridpager');
    let eColumnheader = document.querySelector('.e-columnheader');
    let filterMessageBar = document.getElementById('filterMessageBar');
    let resToolbar = document.querySelector(".e-res-toolbar");

    let eGridcontent = document.querySelector('.e-gridcontent') as HTMLElement | null;
    let eGridpagerWidth = isNotNullAndUndefined(eGridpager) && eGridpager.clientHeight > 0 ? (eGridpager.clientHeight + 2) : 0;
    let eFilterbarWidth = eFilterbar && eFilterbar.clientHeight;
    let eColumnheaderWidth = eColumnheader && eColumnheader.clientHeight;
    let resToolbarHeight = resToolbar && resToolbar.clientHeight;
    let filterMessageBarWidth = filterMessageBar && filterMessageBar.offsetHeight;
    let contentHeight = eGridpagerWidth + eFilterbarWidth + eColumnheaderWidth + filterMessageBarWidth + resToolbarHeight;
    if (isNotNullAndUndefined(eGridcontent))
        eGridcontent.style.height = "calc(100% - " + contentHeight + "px)";
};

export function showHideGridFilter(visible: 'show' | 'hide' | 'auto', grid?: any) {
    if (isNotNullAndUndefined(grid) && isNotNullAndUndefined(grid.element)) {
        let qbSectionOutter = grid.element.closest("#qb-section-outter");
        let qbSection = qbSectionOutter && qbSectionOutter.querySelector("#qb-section");
        // let divGridParent = grid.element.closest("#divGridParent")
        if (isNotNullAndUndefined(qbSection)
            //  && isNotNullAndUndefined(divGridParent)
        ) {
            let qbSectionClassname = qbSection.classList;
            // let qbSectionHeight = qbSection.clientHeight
            if (visible === 'show') {
                qbSection.classList.add('show');
                // divGridParent.style.marginTop = `${qbSectionHeight}px`
            }
            else if (visible === 'hide') {
                qbSection.classList.remove('show');
                // divGridParent.style.marginTop = `0px`
            }
            else if (visible === 'auto') {
                if (qbSectionClassname.contains('show')) {
                    qbSection.classList.remove('show');
                    // divGridParent.style.marginTop = `0px`
                }
                else {
                    qbSection.classList.add('show');
                    // divGridParent.style.marginTop = `${qbSectionHeight}px`
                }
            }
        }
    }
}

export function toggleLeftSideMenuLinkClicked() {
    const sidenav = document.querySelector(".sidebar-nav");
    if (sidenav !== null) { sidenav.classList.remove("mini-navbar"); };

    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop !== null) { transparentBackdrop.classList.remove("show"); };

    const listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader !== null) { listPageHeader.classList.remove("mini-navbar"); };

    const listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent !== null) { listPageContent.classList.remove("mini-navbar"); };

    const topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile !== null) { topNavMenuProfile.classList.remove("mini-navbar"); }
}

export function toggleRightSideMenuLinkClicked() {
    const rightnav = document.querySelector(".rightSidebar");
    if (rightnav !== null) { rightnav.classList.remove("show"); };

    const rightnavpoptriangle = document.querySelector(".pop-tiangle");
    if (rightnavpoptriangle !== null) { rightnavpoptriangle.classList.remove("show"); };

    const outsideClick = document.querySelector(".outsideClick");
    if (outsideClick !== null) { outsideClick.classList.remove("show"); };
}

export function handleScroll() {
    const wScroll = window.scrollY;
    toggleonScroll(wScroll);
    //getScrollspyHeight();
    //getHeaderHeight();
};

// export function backDropToggle() {
//     const transparentBackdrop = document.querySelector(".transparentBackdrop");
//     if (transparentBackdrop !== null) {
//         transparentBackdrop.classList.toggle("show");
//     };
//     const sidenav = document.querySelector(".sidebar-nav");
//     if (sidenav !== null) { sidenav.classList.remove("mini-navbar"); };

//     const sidenavMenu = document.querySelector(".rpm-mp-menu");
//     if (sidenavMenu !== null) { sidenavMenu.classList.toggle("mini-navbar"); };

//     const listPageHeader = document.querySelector(".listPageHeader");
//     if (listPageHeader !== null) { listPageHeader.classList.remove("mini-navbar"); };

//     const pageHeader = document.querySelector(".page-heading");
//     if (pageHeader !== null) { pageHeader.classList.remove("mini-navbar"); };

//     const scrollspyItems = document.querySelector(".scrollspyItems");
//     if (scrollspyItems !== null) { scrollspyItems.classList.remove("mini-navbar"); }

//     const detailsPageContent = document.querySelector(".detailsRightSection");
//     if (detailsPageContent !== null) { detailsPageContent.classList.toggle("mini-navbar"); };

//     const listPageContent = document.querySelector(".page-wrapper-Container");
//     if (listPageContent !== null) { listPageContent.classList.remove("mini-navbar"); };

//     const topNavbar = document.querySelector(".topNavBar ");
//     if (topNavbar !== null) { topNavbar.classList.toggle("mini-navbar"); }

//     const topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
//     if (topNavMenuProfile !== null) { topNavMenuProfile.classList.remove("mini-navbar"); }

//     const header = document.querySelector(".dealDetailsPage");
//     if (header !== null) { header.classList.toggle("mini-navbar"); }

//     const gridHeader = document.querySelector(".e-gridheader");
//     if (gridHeader !== null) { gridHeader.classList.toggle("mini-navbar"); }
//     onToggleSideMenu('close');
// }
export function backDropToggle() {
    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop !== null) {
        transparentBackdrop.classList.toggle("show");
    };
}

export function toggleonScroll(wScroll) {
    const topNavbar = document.querySelector(".topNavBar ");
    //const listHeader = document.querySelector(".listPageHeader ");
    const body = document.querySelector("body");
    const pageHeader = document.querySelector(".page-heading");
    const gridToolbarComponent = document.querySelector(".grid-toolbar-component");
    const detailspageSecondHeader = document.querySelector(".detailspage-second-header");
    const detailsNewRightPanel = document.querySelector(".details-new-rightPanel");
    const pageLayoutSave = document.querySelector(".pageLayout-save");
    //const detailsLeftSection = document.querySelector(".detailsLeftSection");
    //const dealDetailsPage = document.querySelector(".dealDetailsPage");
    //const rightTagsModal = document.querySelector(".rightTagsModal");
    const gridHeader = document.querySelector(".e-gridheader");
    const gridContent = document.querySelector(".e-gridcontent");
    const focusSection = document.querySelector("#focusSection");

    let detailSecondHeaderDiv = document.getElementById('detailSecondHeaderDiv');
    let pageOuterDiv = document.getElementById('pageOuterDivTab');
    let detailsNewRightPanelDiv = document.getElementById('detailsRightpanelDiv');

    if (wScroll > 120) {

        if (body !== null) {
            if (body.classList.contains(".mini-navbar")) {
                body.classList.add("mini-navbar");
                body.classList.add("scrolling-navbar");
                body.classList.add("top-nav-collapse");
            }
            else {
                body.classList.add("scrolling-navbar");
                body.classList.add("top-nav-collapse");
            }
        }

        if (topNavbar !== null) {
            if (topNavbar.classList.contains(".mini-navbar")) {
                topNavbar.classList.add("mini-navbar");
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
            else {
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
        }

        if (focusSection !== null) {
            if (focusSection.classList.contains(".mini-navbar")) {
                focusSection.classList.add("mini-navbar");
                focusSection.classList.add("scrolling-navbar");
                focusSection.classList.add("top-nav-collapse");
            }
            else {
                focusSection.classList.add("scrolling-navbar");
                focusSection.classList.add("top-nav-collapse");
            }
        }
        //if (listHeader !== null) {
        //    if (listHeader.classList.contains(".mini-navbar")) {
        //        listHeader.classList.add("mini-navbar");
        //        listHeader.classList.add("scrolling-navbar top-nav-collapse");
        //        listHeader.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        listHeader.classList.add("scrolling-navbar");
        //        listHeader.classList.add("top-nav-collapse");
        //    }
        //}
        if (pageHeader !== null) {
            if (pageHeader.classList.contains(".mini-navbar")) {
                pageHeader.classList.add("mini-navbar");
                pageHeader.classList.add("scrolling-navbar top-nav-collapse");
                pageHeader.classList.add("top-nav-collapse");
            }
            else {
                pageHeader.classList.add("scrolling-navbar");
                pageHeader.classList.add("top-nav-collapse");
            }
        }
        if (gridToolbarComponent !== null) {
            if (gridToolbarComponent.classList.contains(".mini-navbar")) {
                gridToolbarComponent.classList.add("mini-navbar");
                gridToolbarComponent.classList.add("scrolling-navbar top-nav-collapse");
                gridToolbarComponent.classList.add("top-nav-collapse");
            }
            else {
                gridToolbarComponent.classList.add("scrolling-navbar");
                gridToolbarComponent.classList.add("top-nav-collapse");
            }
        }
        if (detailspageSecondHeader !== null) {
            if (detailspageSecondHeader.classList.contains(".mini-navbar")) {
                detailspageSecondHeader.classList.add("mini-navbar");
                detailspageSecondHeader.classList.add("scrolling-navbar top-nav-collapse");
                detailspageSecondHeader.classList.add("top-nav-collapse");
            }
            else {
                detailspageSecondHeader.classList.add("scrolling-navbar");
                detailspageSecondHeader.classList.add("top-nav-collapse");
            }
        }
        if (detailsNewRightPanel !== null) {
            if (detailsNewRightPanel.classList.contains(".mini-navbar")) {
                detailsNewRightPanel.classList.add("mini-navbar");
                detailsNewRightPanel.classList.add("scrolling-navbar top-nav-collapse");
                detailsNewRightPanel.classList.add("top-nav-collapse");
            }
            else {
                detailsNewRightPanel.classList.add("scrolling-navbar");
                detailsNewRightPanel.classList.add("top-nav-collapse");
            }
        }
        if (pageLayoutSave !== null) {
            if (pageLayoutSave.classList.contains(".mini-navbar")) {
                pageLayoutSave.classList.add("mini-navbar");
                pageLayoutSave.classList.add("scrolling-navbar top-nav-collapse");
                pageLayoutSave.classList.add("top-nav-collapse");
            }
            else {
                pageLayoutSave.classList.add("scrolling-navbar");
                pageLayoutSave.classList.add("top-nav-collapse");
            }
        }
        //if (detailsLeftSection !== null) {
        //    if (detailsLeftSection.classList.contains(".mini-navbar")) {
        //        detailsLeftSection.classList.add("mini-navbar");
        //        detailsLeftSection.classList.add("scrolling-navbar top-nav-collapse");
        //        detailsLeftSection.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        detailsLeftSection.classList.add("scrolling-navbar");
        //        detailsLeftSection.classList.add("top-nav-collapse");
        //    }
        //}
        //if (dealDetailsPage !== null) {
        //    if (dealDetailsPage.classList.contains(".mini-navbar")) {
        //        dealDetailsPage.classList.add("mini-navbar");
        //        dealDetailsPage.classList.add("scrolling-navbar top-nav-collapse");
        //        dealDetailsPage.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        dealDetailsPage.classList.add("scrolling-navbar");
        //        dealDetailsPage.classList.add("top-nav-collapse");
        //    }
        //}
        //if (rightTagsModal !== null) {
        //    if (rightTagsModal.classList.contains(".mini-navbar")) {
        //        rightTagsModal.classList.add("mini-navbar");
        //        rightTagsModal.classList.add("scrolling-navbar top-nav-collapse");
        //        rightTagsModal.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        rightTagsModal.classList.add("scrolling-navbar");
        //        rightTagsModal.classList.add("top-nav-collapse");
        //    }
        //}
        if (gridHeader !== null) {
            if (gridHeader.classList.contains(".mini-navbar")) {
                gridHeader.classList.add("mini-navbar");
                gridHeader.classList.add("fixedHeader ");
            }
            else {
                gridHeader.classList.add("fixedHeader");
            }
        }
        if (gridContent !== null) {
            if (gridContent.classList.contains(".mini-navbar")) {
                gridContent.classList.add("mini-navbar");
                gridContent.classList.add("fixedHeader");
            }
            else {
                gridContent.classList.add("fixedHeader");
            }
        }
        if (isNotNullAndUndefined(detailSecondHeaderDiv) && isNotNullAndUndefined(pageOuterDiv) && isNotNullAndUndefined(detailsNewRightPanelDiv)) {
            pageOuterDiv.style.setProperty('padding-top', detailSecondHeaderDiv.offsetHeight + 40 + "px", 'important');
            detailsNewRightPanelDiv.style.setProperty('top', detailSecondHeaderDiv.offsetHeight + "px", 'important');
        }
    }
    else if (wScroll < 120) {

        if (body !== null) {
            if (body.classList.contains(".mini-navbar")) {
                body.classList.add("scrolling-navbar");
                body.classList.add("top-nav-collapse");
            }
            else {
                body.classList.remove("scrolling-navbar");
                body.classList.remove("top-nav-collapse");
            }
        }
        if (topNavbar !== null) {
            if (topNavbar.classList.contains(".mini-navbar")) {
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
            else {
                topNavbar.classList.remove("scrolling-navbar");
                topNavbar.classList.remove("top-nav-collapse");
            }
        }
        if (focusSection !== null) {
            if (focusSection.classList.contains(".mini-navbar")) {
                focusSection.classList.add("scrolling-navbar");
                focusSection.classList.add("top-nav-collapse");
            }
            else {
                focusSection.classList.remove("scrolling-navbar");
                focusSection.classList.remove("top-nav-collapse");
            }
        }


        //if (listHeader !== null) {
        //    if (listHeader.classList.contains(".mini-navbar")) {
        //        listHeader.classList.add("scrolling-navbar");
        //        listHeader.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        listHeader.classList.remove("scrolling-navbar");
        //        listHeader.classList.remove("top-nav-collapse");
        //    }
        //}
        if (pageHeader !== null) {
            if (pageHeader.classList.contains(".mini-navbar")) {
                pageHeader.classList.add("scrolling-navbar");
                pageHeader.classList.add("top-nav-collapse");
            }
            else {
                pageHeader.classList.remove("scrolling-navbar");
                pageHeader.classList.remove("top-nav-collapse");
            }
        }
        if (gridToolbarComponent !== null) {
            if (gridToolbarComponent.classList.contains(".mini-navbar")) {
                gridToolbarComponent.classList.add("scrolling-navbar");
                gridToolbarComponent.classList.add("top-nav-collapse");
            }
            else {
                gridToolbarComponent.classList.remove("scrolling-navbar");
                gridToolbarComponent.classList.remove("top-nav-collapse");
            }
        }
        if (detailspageSecondHeader !== null) {
            if (detailspageSecondHeader.classList.contains(".mini-navbar")) {
                detailspageSecondHeader.classList.add("scrolling-navbar");
                detailspageSecondHeader.classList.add("top-nav-collapse");
            }
            else {
                detailspageSecondHeader.classList.remove("scrolling-navbar");
                detailspageSecondHeader.classList.remove("top-nav-collapse");
            }
        }
        if (detailsNewRightPanel !== null) {
            if (detailsNewRightPanel.classList.contains(".mini-navbar")) {
                detailsNewRightPanel.classList.add("scrolling-navbar");
                detailsNewRightPanel.classList.add("top-nav-collapse");
            }
            else {
                detailsNewRightPanel.classList.remove("scrolling-navbar");
                detailsNewRightPanel.classList.remove("top-nav-collapse");
            }
        }
        if (pageLayoutSave !== null) {
            if (pageLayoutSave.classList.contains(".mini-navbar")) {
                pageLayoutSave.classList.add("scrolling-navbar");
                pageLayoutSave.classList.add("top-nav-collapse");
            }
            else {
                pageLayoutSave.classList.remove("scrolling-navbar");
                pageLayoutSave.classList.remove("top-nav-collapse");
            }
        }
        //if (detailsLeftSection !== null) {
        //    if (detailsLeftSection.classList.contains(".mini-navbar")) {
        //        detailsLeftSection.classList.add("scrolling-navbar");
        //        detailsLeftSection.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        detailsLeftSection.classList.remove("scrolling-navbar");
        //        detailsLeftSection.classList.remove("top-nav-collapse");
        //    }
        //}
        //if (dealDetailsPage !== null) {
        //    if (dealDetailsPage.classList.contains(".mini-navbar")) {
        //        dealDetailsPage.classList.add("scrolling-navbar");
        //        dealDetailsPage.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        dealDetailsPage.classList.remove("scrolling-navbar");
        //        dealDetailsPage.classList.remove("top-nav-collapse");
        //    }
        //}
        //if (rightTagsModal !== null) {
        //    if (rightTagsModal.classList.contains(".mini-navbar")) {
        //        rightTagsModal.classList.add("scrolling-navbar");
        //        rightTagsModal.classList.add("top-nav-collapse");
        //    }
        //    else {
        //        rightTagsModal.classList.remove("scrolling-navbar");
        //        rightTagsModal.classList.remove("top-nav-collapse");
        //    }
        //}
        if (gridHeader !== null) {
            if (gridHeader.classList.contains(".mini-navbar")) {
                gridHeader.classList.add("mini-navbar");
                gridHeader.classList.add("fixedHeader ");
            }
            else {
                gridHeader.classList.remove("fixedHeader");
            }
        }
        if (gridContent !== null) {
            if (gridContent.classList.contains(".mini-navbar")) {
                gridContent.classList.add("mini-navbar");
                gridContent.classList.add("fixedHeader");
            }
            else {
                gridContent.classList.remove("fixedHeader");
            }
        }
        if (isNotNullAndUndefined(detailSecondHeaderDiv) && isNotNullAndUndefined(pageOuterDiv) && isNotNullAndUndefined(detailsNewRightPanelDiv)) {
            pageOuterDiv.style.setProperty('padding-top', detailSecondHeaderDiv.offsetHeight + "px", 'important');
            detailsNewRightPanelDiv.style.setProperty('top', detailSecondHeaderDiv.offsetHeight + 62 + "px", 'important');
        }
    }
}
export function getHeaderHeight() {

    const scrollspyItemsOuter = document.getElementById("scrollspyItems");
    //console.log("scrollspyItemsHeight", scrollspyItemsHeight);
    const headerHeightOuter = document.getElementById("page-heading");
    let scrollspyItems;
    let pageOuterDivTab;
    if (headerHeightOuter !== null && scrollspyItemsOuter !== null) {
        const scrollspyItemsHeight = document.getElementById("scrollspyItems").offsetHeight;
        const headerHeight = document.getElementById("page-heading").offsetHeight;
        if (scrollspyItemsHeight > 0) {
            scrollspyItems = headerHeight + "px";
            pageOuterDivTab = headerHeight - 43 + "px";
            if (isNotNullAndUndefined(document.getElementById("pageOuterDivTab")) &&
                isNotNullAndUndefined(document.getElementById("scrollspyItems"))
            ) {
                document.getElementById("pageOuterDivTab").style.paddingTop = pageOuterDivTab;
                document.getElementById("scrollspyItems").style.top = scrollspyItems;
            }
        }
        else {
            scrollspyItems = headerHeight + "px";
            pageOuterDivTab = headerHeight - 78 + "px";
            if (isNotNullAndUndefined(document.getElementById("pageOuterDivTab")) &&
                isNotNullAndUndefined(document.getElementById("scrollspyItems"))
            ) {
                document.getElementById("pageOuterDivTab").style.paddingTop = pageOuterDivTab;
                document.getElementById("scrollspyItems").style.top = scrollspyItems;
            }
        }
    }
    //const headerHeight = document.getElementById("page-heading").offsetHeight;
    //const scrollspyItemsHeight = document.getElementById("scrollspyItems").offsetHeight;
    //const scrollspyItems = headerHeight + "px";
    //const pageOuterDivTab = headerHeight - scrollspyItemsHeight + 10 + "px";
    //document.getElementById("pageOuterDivTab").style.paddingTop = pageOuterDivTab;
    //document.getElementById("scrollspyItems").style.top = scrollspyItems;
    let detailSecondHeaderDiv = document.getElementById('detailSecondHeaderDiv');
    let pageOuterDiv = document.getElementById('pageOuterDivTab');
    let detailsNewRightPanelDiv = document.getElementById('detailsRightpanelDiv');

    if (isNotNullAndUndefined(detailSecondHeaderDiv) && isNotNullAndUndefined(pageOuterDiv) && isNotNullAndUndefined(detailsNewRightPanelDiv)) {
        pageOuterDiv.style.setProperty('padding-top', detailSecondHeaderDiv.offsetHeight + "px", 'important');
        detailsNewRightPanelDiv.style.setProperty('top', detailSecondHeaderDiv.offsetHeight + 62 + "px", 'important');
    }
}
export function getScrollspyHeight() {
    const headerHeightOuter = document.getElementById("page-heading");
    const topNavBarOuter = document.getElementById("topNavBar");
    if (headerHeightOuter !== null && topNavBarOuter !== null) {
        const headerHeight = headerHeightOuter.offsetHeight;
        const topNavBarHeight = topNavBarOuter.offsetHeight;
        const scrollspyItems = headerHeight + topNavBarHeight + "px";
        document.getElementById("scrollspyItems").style.top = scrollspyItems;
    }
}

export const getUserClientInfo = () => {
    let url = "User/GetClientInfo";
    return new Promise<ClientInfoRow>((resolve/*, reject*/) => {
        return repositoryActions.getData(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)) {
                resolve(response.data);
            }
            else {
                resolve(null);
            }
        })
            .catch((error) => resolve(null));
    });
};

export const getUserInfo = () => {
    let url = "User/UserInfo";
    return new Promise<UserInfoRow | number>((resolve/*, reject*/) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data)) {
                let businessUnitRow: UserInfoRow = response.data as UserInfoRow;
                resolve(businessUnitRow);
            }
            else {
                if (isNotNullAndUndefined(response.status)) {
                    resolve(response.status);
                }
                else if (isNotNullAndUndefined(response['response']) && isNotNullAndUndefined(response['response'].status)) {
                    resolve(response['response'].status);
                }
            }
        })
            .catch((error) => {
                //console.log("getUserInfo api call end catch", error);
                if (error.response) {
                    // Handle token error here
                    // For example, redirect to login page or display an error message
                    resolve(error.response.status);
                }
            });
    });
};

export const IsUserImpersonated = () => {
    return new Promise<boolean>((resolve, reject) => {
        repositoryActions.postDataAndGetResponse("User/IsLogInImpersonatedUser", null, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    if (response.data === true)
                        resolve(true);
                    else
                        resolve(false);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => resolve(false));
    });
};

export const CanImpersonateUser = () => {
    return new Promise<boolean>((resolve, reject) => {
        repositoryActions.postDataAndGetResponse("User/CanImpersonateUser", null, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    if (response.data === true) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(false);
                }
            }).catch((error) => error);
    });
};

export const LogoutImpersonatedUser = () => {
    //code start for clear bu on logout
    //sessionStorage.removeItem(StorageKey.CurrentPortal);
    const msalInstanceCurrentUser = msalInstance.currentUser;
    msalInstanceCurrentUser.CurrentPortal = null;
    msalInstanceCurrentUser.CurrentPortalUID = null;
    msalInstanceCurrentUser['currentBusinessUnitId'] = null;
    msalInstance.currentUser = msalInstanceCurrentUser;


    let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
    if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
        currentUser.CurrentPortal = null;
        currentUser.CurrentPortalUID = null;
        currentUser['currentBusinessUnitId'] = null;
    }

    setSessionStorage(StorageKey.CurrentPortal, true, null);
    setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));

    //code end for clear bu on logout

    repositoryActions.postDataAndGetResponse("User/LogoutImpersonatedUser", null, null, ContentType.applicationJson)
        .then(async (response) => {
            if (isNotNullAndUndefined(response.data)) {
                try {
                    const userLoginLogoutImpersonatedUser = await UserLoginLogoutImpersonatedUser();
                } catch (error) {//added explicityly by jagan
                }
                //showSuccessToast('Impersonated User logged out', 'Success');
                sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                sessionStorage.removeItem(StorageKey.MenuModel);
                sessionStorage.removeItem(StorageKey.Terminologies);
                // sessionStorage.removeItem(StorageKey.all_businessUnit);
                localStorage.removeItem(StorageKey.currentOrganisation);
                setImpersonateUserDefinition(null);
                window.location.href = '/';
            }
        }).catch((error) => error);
};

const UserLoginLogoutImpersonatedUser = () => {
    return new Promise<boolean>((resolve) => {
        const url = Constants.baseRAFXeroUrl +
            "api/" +
            "UserLogin/LogoutImpersonatedUser";
        repositoryActions.postDataAndGetResponse(url, null, null, ContentType.applicationJson)
            .then((response) => {
                resolve(true);
            }).catch((error) => {
                console.error("UserLogin/LogoutImpersonatedUser error", error);
                resolve(false);
            });
    });
};

export const UserLoginLogInAs = (logInAsRequestRow: LogInAsRequestRow) => {
    return new Promise<boolean>((resolve) => {
        const url = Constants.baseRAFXeroUrl +
            "api/" +
            "UserLogin/LogInAs";
        repositoryActions.postDataAndGetResponse(url, logInAsRequestRow, null, ContentType.applicationJson)
            .then((response) => {
                resolve(true);
            }).catch((error) => {
                console.error("UserLogin/LogInAs error", error);
                resolve(false);
            });
    });
};

export const addToFocusList = (relatedTo: string, relatedToType: string, relatedToUID: string) => {
    let focusRow: FocusRow = new FocusRow();
    const loggedinUserName = msalInstance.currentUserName;
    const loggedinUserId = msalInstance.currentUserId;
    focusRow.RelatedTo = relatedTo;
    focusRow.RelatedToType = relatedToType;
    focusRow.RelatedToUID = relatedToUID;
    focusRow.FocusUserType = RAFFocusUserType.User;
    focusRow.FocusUser = loggedinUserName;
    focusRow.FocusUserUID = loggedinUserId;

    return new Promise<string>((resolve/*, reject*/) => {
        return repositoryActions.postDataAndGetResponse(
            'UserFocus/Save',
            getSaveRequest(focusRow, null),
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.EntityId)) {
                let EntityId: string = response.data.EntityId as string;
                resolve(EntityId);
            }
            else {
                resolve(null);
            }
        });
    });
};

export const updateFocusList = (relatedTo: string, relatedToType: string, relatedToUID: string, focusListId: string) => {
    let focusRow: FocusRow = new FocusRow();
    const loggedinUserName = msalInstance.currentUserName;
    const loggedinUserId = msalInstance.currentUserId;
    focusRow.RelatedTo = relatedTo;
    focusRow.RelatedToType = relatedToType;
    focusRow.RelatedToUID = relatedToUID;
    focusRow.FocusUser = loggedinUserName;
    focusRow.FocusUserUID = loggedinUserId;

    return new Promise<string>((resolve/*, reject*/) => {
        return repositoryActions.postDataAndGetResponse(
            'UserFocus/Save',
            getSaveRequest(focusRow, focusListId),
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data)) {
                resolve(response.data.EntityId);
            }
            else {
                resolve(null);
            }
        });
    });
};

export const navigateToDashboard = (navigate?: NavigateFunction) => {
    setSessionStorage(RMStorageKey.isAdmin, false, 'false');
    //setSessionStorage(RMStorageKey.isUserSettings, false, 'false');
    //setSessionStorage(RMStorageKey.isContentLibrary, false, 'false');
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.Default);

    setTimeout(() => {
        if (navigate) {
            //navigate('/home');
            navigate('/');
        } else {
            // window.location.href = '/home';
            window.location.href = '/';
        }
    });
};

export const ChangeWorkspaceClicked = (item?: WorkspaceRow) => {
    setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(item));
    //setSessionStorage(StorageKey.MenuModel, false, JSON.stringify(item));
    // setTimeout(() => {
    //     window.location.href = '/Dashboard';
    // });
    navigateToDashboard();
};

export const AdministrationClicked = (navigate?: NavigateFunction) => {
    /*let navMenu = document.querySelector('.nav_menu_Items');
    let userTeamMenu = document.querySelector('.userTeam_menu_Items');
    if (isNotNullAndUndefined(navMenu)) {
        navMenu.classList.add("hidden");
    }
    if (isNotNullAndUndefined(userTeamMenu)) {
        userTeamMenu.classList.remove("hidden");
    }
*/
    /*let adminMenu = document.querySelectorAll('.adminMenu');
    let mainMenu = document.querySelectorAll('.mainMenu');
    if (isNotNullAndUndefined(adminMenu)) {
        adminMenu.forEach(item => {
            item.classList.add('show');
        })
    }
    if (isNotNullAndUndefined(mainMenu)) {
        mainMenu.forEach(item => {
            item.classList.add('hide');
        })
    }*/
    //removeSessionStorage(StorageKey.MenuModel, true);
    //removeSessionStorage(RMStorageKey.currentWorkspace);
    setSessionStorage(RMStorageKey.isAdmin, false, 'true');
    //setSessionStorage(RMStorageKey.isUserSettings, false, 'false');
    //setSessionStorage(RMStorageKey.isContentLibrary, false, 'false');
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.AdminSettings);
    setTimeout(() => {
        //window.location.href = '/User/List';
        if (isNotNullAndUndefined(navigate)) {
            // navigate('/user/list');
            navigate('/team/list');
        } else {
            //window.location.href = '/user/list';
            window.location.href = '/team/list';
        }
    });
};


export const SystemSettingsClicked = (navigate?: NavigateFunction) => {
    setSessionStorage(RMStorageKey.isAdmin, false, 'false');
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.SystemSettings);
    setTimeout(() => {
        if (isNotNullAndUndefined(navigate)) {
            navigate('/user/list');
        } else {
            window.location.href = '/user/list';
        }
    });
};

export const ESIOBuilderClicked = (navigate?: NavigateFunction) => {
    setSessionStorage(RMStorageKey.isAdmin, false, 'false');
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.ESIOBuilder);
    setTimeout(() => {
        if (isNotNullAndUndefined(navigate)) {
            navigate('/processlibrary/list');
        } else {
            window.location.href = '/processlibrary/list';
        }
    });
};

export const OnClickNavigateToPortal = (navigate: NavigateFunction, viewName: string, setSelectedViewState: any) => {
    if (isNotNullAndUndefined(viewName)) {
        const currentView = getPortalViewByViewName(viewName);
        setSessionStorage(
            StorageKey.recentViewId_modulename + RAFEntityName.Portal,
            true,
            JSON.stringify({ viewId: currentView.Name, viewName: viewName, category: RAFViewType.SystemView })
        );
        if (isNotNullAndUndefined(setSelectedViewState)) {
            setSelectedViewState(currentView);
        }
    }

    setTimeout(() => {
        if (isNotNullAndUndefined(navigate)) {
            navigate("/portal/list/", {
                state: { hideSidebarComponent: true },
            });
        } else {
            window.location.href = '/portal/list/';
        }
    });
};

export const RefreshUserStorage = (reloadSamePage = false) => {
    sessionStorage.clear();
    localStorage.removeItem(StorageKey.currentOrganisation);
    localStorage.removeItem("survey-json");

    setTimeout(() => {
        if (reloadSamePage === true) {
            window.location.reload();
        }
        else {
            window.location.href = '/';
        }
    });
};

export const UserSettingsClicked = (navigate?: NavigateFunction) => {
    //removeSessionStorage(StorageKey.MenuModel, true);
    //removeSessionStorage(RMStorageKey.currentWorkspace);
    setSessionStorage(RMStorageKey.isAdmin, false, 'false');
    //setSessionStorage(RMStorageKey.isUserSettings, false, 'true');
    //setSessionStorage(RMStorageKey.isContentLibrary, false, 'false');
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.UserSettings);
    setTimeout(() => {
        if (isNotNullAndUndefined(navigate)) {
            navigate('/myprofile');
            //navigate('/signature/list');
            //navigate('/Settings');
        } else {
            //window.location.href = '/Settings';
            window.location.href = '/myprofile';
            // window.location.href = '/signature/list';
        }
    });
};

export const ContentLibraryClicked = () => {
    setSessionStorage(RMStorageKey.isAdmin, false, 'false');
    //setSessionStorage(RMStorageKey.isUserSettings, false, 'false');
    //setSessionStorage(RMStorageKey.isContentLibrary, false, 'true');
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.ContentManagement);
    setTimeout(() => {
        window.location.href = '/Settings';
    });
};

export function GetRelatedCount(url: string, viewName?: string, pageNumber?: number, pageSize?: number, sort?: RAFSort, customFilter?: RAFCustomFilter): Promise<RAFDataResult> {
    let rafSFGridService: RAFSFGridService = new RAFSFGridService();
    return rafSFGridService.getData(url, null, pageNumber, pageSize, sort, customFilter, null, viewName);
}

export const getFirebaseAuthToken = () => {
    let url = "Activity/FirebaseAuthToken";
    return new Promise<string>((resolve/*, reject*/) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data)) {
                let fbAuthToken: string = response.data as string;
                resolve(fbAuthToken);
            }
            else {
                resolve(null);
            }
        });
    });
};


export const getMyUserGroups = () => {
    return new Promise<UserGroupRow[]>((resolve/*, reject*/) => {
        let url = `${Constants.baseAPIUrl}Team/MyTeams`;
        const myTeamsStorage = getSessionStorage(StorageKey.myUserGroups, true);
        if (
            isNotNullAndUndefined(myTeamsStorage) &&
            !IsNullOrWhiteSpace(myTeamsStorage)
        ) {
            const myTeams: UserGroupRow[] = JSON.parse(myTeamsStorage);
            if (isNotNullAndUndefined(myTeams)) {
                resolve(myTeams);
            }
        } else {
            return repositoryActions
                .postDataAndGetResponse(
                    url,
                    { Skip: 0 },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    const myTeams: UserGroupRow[] = response.data.Entities;
                    setSessionStorage(StorageKey.myUserGroups, true, JSON.stringify(myTeams));
                    resolve(myTeams);
                })
                ;
        }
    });
};


export const initUserAndTeam = () => {
    return new Promise<LookUpGroupRow[]>(async (resolve/*, reject*/) => {
        let retVal: LookUpGroupRow[] = [];
        const [userList, teamList] = await Promise.all([getAllUsers(), getAllUserGroups()]);
        if (isNotNullAndUndefined(userList)) {
            userList.forEach((item) => {
                retVal.push({
                    UID: item.UID.toString(),
                    Value: item.Value,
                    Catagory: RAFUserTeam.User,
                });
            });
        }

        if (isNotNullAndUndefined(teamList)) {
            teamList.forEach((item) => {
                retVal.push({
                    UID: item.UID.toString(),
                    Value: item.Value,
                    Catagory: RAFUserTeam.Team,
                });
            });
        }
        resolve(retVal);
    });
};

export function getRedirectURLByModule(moduleName: string): string {
    let entityName = getURLNameByModuleName(moduleName);
    //entityName = isNotNullAndUndefined(entityName) ? entityName.charAt(0).toUpperCase() + entityName.slice(1) : null;
    let retVal = "/" + entityName + "/view/";
    return retVal;
    // switch (moduleName) {
    //     case RAFEntityName.Account:
    //         retVal = "/Company/View/";
    //         break;
    //     case RAFEntityName.Contact:
    //         retVal = "/Contact/View/";
    //         break;
    //     case RAFEntityName.Deal:
    //         retVal = "/Deal/View/";
    //         break;
    //     case RAFEntityName.Ticket:
    //         retVal = "/Ticket/View/";
    //         break;
    //         Case 
    //     default:
    //         retVal =  "/" + moduleName + "/View/"
    //         break;
    // }
}

export function resetSidemenuWidth() {
    let eSidebarRightCol = document.getElementById("e-sidebar_right_col");

    if (isNotNullAndUndefined(eSidebarRightCol)) {
        eSidebarRightCol.innerHTML = "";
    }

    let listSidebar = document.getElementById("listSidebar");
    let sidebarContent = document.getElementById("sidebar-content");
    const eSidebarLeft = document.querySelector("#eSidebarLeft");
    const listSidebarRight = document.querySelector("#listSidebar_right");
    let navigationElementWidth = (isNotNullAndUndefined(eSidebarLeft) && eSidebarLeft.clientWidth) + (isNotNullAndUndefined(listSidebarRight) && listSidebarRight.clientWidth);
    let windowWidth = window.innerWidth;
    if (isNotNullAndUndefined(listSidebar)) {
        listSidebar.style.width = `${navigationElementWidth}px`;
    }
    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
        sidebarContent.style.marginLeft = `${navigationElementWidth}px`;
    }
}
