import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  DropDownButtonComponent,
  ItemModel,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
} from "react";
import { msalInstance } from "../..";
import RAFIconImage from "../../RAFComponents/Navigation/RAFIconImage";
import {
  ISESIOAppAdministrator,
  Logout,
} from "../../RAFComponents/helpers/AppHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../RAFComponents/helpers/utils";
import { TenantRow } from "../../RAFComponents/models/Common/TenantRow";
import SwitchWorkspace from "../../RAFModules/Common/Workspace/SwitchWorkspace";
import { NavigateParamsProps, withRouter } from "../../router";
import {
  AdministrationClicked,
  IsUserImpersonated,
  LogoutImpersonatedUser,
  OnClickNavigateToPortal,
  SystemSettingsClicked,
  UserSettingsClicked,
  RefreshUserStorage,
  ESIOBuilderClicked,
} from "../ACutils";
import SwitchOrganisation from "../SwitchOrganisation";

import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import UserProfilePhoto from "../../RAFModules/ActiveContacts/User/UserProfilePhoto";
import { atomSelectedViewState } from "../../RAFModules/Common/List/IndexHelper";
import { CurrentWorkspaceContext } from "../../RAFModules/Common/Providers/WorkspaceContextProvider";
import { PortalDefaultView } from "../../RAFModules/Common/Workspace/WorkspaceHelper";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { MenuCategory } from "../../constants/MenuConstant/RAFMenuCategory";
import SwitchTenant from "../SwitchTenant";
import "./RAFProfileCard.scss";
import { RolePermissionsContext } from "../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { hasPermission } from "../../RAFComponents/helpers/PermissionHelper";
import { PermissionConstants } from "../../constants/Common/PermissionConstants";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getAWSToken } from "../../services/getAWSToken";
import { JWT, decodeJWT } from "aws-amplify/auth";

interface IProps {
  arrowTipClass?: string;
  onUpdatMenuCategory?: (menu: string) => void;
  uiMode?: "fullBtn";
}

interface IState {
  showWorkspaceContent: boolean;
  showSwitchTenantDialog: boolean;
  showSwitchOrganisationDialog: boolean;
}

function RAFProfileCard({
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const setSelectedViewState = useSetRecoilState(
    atomSelectedViewState(RAFEntityName.Portal)
  );

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hasPermissionToAdministration = hasPermission(
    permissionValue,
    PermissionConstants.ShowAdministrationRead
  );

  const navigate = useNavigate();
  const currentUserTenants = msalInstance.currentTenants;
  const userName = msalInstance.currentUserName;
  const userId = msalInstance.currentUserId;
  const userEmail = msalInstance.currentUserEmail;
  const userNameInitial =
    isNotNullAndUndefined(userName) && userName.charAt(0).toUpperCase();
  const selectedTenant: TenantRow = isNotEmptyArray(currentUserTenants)
    ? currentUserTenants.find((x) => x.UID === msalInstance.currentTenantId)
    : null;
  const selectedTenantName = isNotNullAndUndefined(selectedTenant)
    ? selectedTenant.DisplayName
    : "na";

  const currentWorkspaceContext = useContext(CurrentWorkspaceContext);
  const currentWorkspace =
    isNotNullAndUndefined(currentWorkspaceContext) &&
      isNotNullAndUndefined(currentWorkspaceContext.currentWorkspace)
      ? currentWorkspaceContext.currentWorkspace
      : null;
  const currentPortalName = isNotNullAndUndefined(currentWorkspace)
    ? currentWorkspace.PortalName
    : selectedTenantName;
  const { signOut, user } = useAuthenticator((context) => [context.user]);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showWorkspaceContent: false,
      showSwitchTenantDialog: false,
      showSwitchOrganisationDialog: false,
    }
  );

  function profileDropdownBtnClicked(e) {
    e.stopPropagation();
    let profileDivMyPortalsBtn = document.getElementsByClassName(
      "profileDivMyPortalsBtn"
    );
    let profileDivSwitchTenantBtn = document.getElementsByClassName(
      "profileDivSwitchTenantBtn"
    );
    let profileDivAdministrationBtn = document.getElementsByClassName(
      "profileDivAdministrationBtn"
    );
    let profileDivESIOBuilderBtn = document.getElementsByClassName(
      "profileDivESIOBuilderBtn"
    );
    let profileDivSystemSettingsBtn = document.getElementsByClassName(
      "profileDivSystemSettingsBtn"
    );
    let profileDivUserSettingsBtn = document.getElementsByClassName(
      "profileDivUserSettingsBtn"
    );
    let profileDivCategoryBtn = document.getElementsByClassName(
      "profileDivCategoryBtn"
    );
    let profileDivSwitchOrganisationBtn = document.getElementsByClassName(
      "profileDivSwitchOrganisationBtn"
    );
    let profileDivUserLogOutBtn = document.getElementsByClassName(
      "profileDivUserLogOutBtn"
    );
    let profileDivUserRefreshDataBtn = document.getElementsByClassName(
      "profileDivUserRefreshDataBtn"
    );

    if (isNotNullAndUndefined(profileDivMyPortalsBtn)) {
      for (let i = 0; i < profileDivMyPortalsBtn.length; i++) {
        let profileDivMyPortalsHTMLBtn = profileDivMyPortalsBtn[
          i
        ] as HTMLElement;
        profileDivMyPortalsHTMLBtn.onclick = () => {
          OnClickNavigateToPortal(
            props.navigate,
            PortalDefaultView.MyPortalView.DisplayName,
            setSelectedViewState
          );
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.AdminSettings);
          }
        };
      }
    }

    if (isNotNullAndUndefined(profileDivSwitchTenantBtn)) {
      for (let i = 0; i < profileDivSwitchTenantBtn.length; i++) {
        let profileDivMyOrganizationHTMLBtn = profileDivSwitchTenantBtn[
          i
        ] as HTMLElement;
        profileDivMyOrganizationHTMLBtn.onclick = () => {
          closeDropdownBtn();
          // switchTenantDialogOpen();
          navigate("/SwitchTenant");
        };
      }
    }

    if (isNotNullAndUndefined(profileDivAdministrationBtn)) {
      for (let i = 0; i < profileDivAdministrationBtn.length; i++) {
        let profileDivAdministrationHTMLBtn = profileDivAdministrationBtn[
          i
        ] as HTMLElement;
        profileDivAdministrationHTMLBtn.onclick = () => {
          AdministrationClicked(props.navigate);
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.AdminSettings);
          }
        };
      }
    }

    if (isNotNullAndUndefined(profileDivESIOBuilderBtn)) {
      for (let i = 0; i < profileDivESIOBuilderBtn.length; i++) {
        let profileDivESIOBuilderHTMLBtn = profileDivESIOBuilderBtn[
          i
        ] as HTMLElement;
        profileDivESIOBuilderHTMLBtn.onclick = () => {
          ESIOBuilderClicked(props.navigate);
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.ESIOBuilder);
          }
        };
      }
    }

    if (isNotNullAndUndefined(profileDivUserSettingsBtn)) {
      for (let i = 0; i < profileDivUserSettingsBtn.length; i++) {
        let profileDivUserSettingsHTMLBtn = profileDivUserSettingsBtn[
          i
        ] as HTMLElement;
        profileDivUserSettingsHTMLBtn.onclick = () => {
          UserSettingsClicked(props.navigate);
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.UserSettings);
          }
        };
      }
    }
    if (isNotNullAndUndefined(profileDivSystemSettingsBtn)) {
      for (let i = 0; i < profileDivSystemSettingsBtn.length; i++) {
        let profileDivUserSettingsHTMLBtn = profileDivSystemSettingsBtn[
          i
        ] as HTMLElement;
        profileDivUserSettingsHTMLBtn.onclick = () => {
          SystemSettingsClicked(props.navigate);
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.SystemSettings);
          }
        };
      }
    }
    if (isNotNullAndUndefined(profileDivCategoryBtn)) {
      for (let i = 0; i < profileDivCategoryBtn.length; i++) {
        let profileDivUserSettingsHTMLBtn = profileDivCategoryBtn[
          i
        ] as HTMLElement;
        profileDivUserSettingsHTMLBtn.onclick = () => {
          props.navigate("/Category/List");
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.UserSettings);
          }
        };
      }
    }

    if (isNotNullAndUndefined(profileDivESIOBuilderBtn)) {
      for (let i = 0; i < profileDivESIOBuilderBtn.length; i++) {
        let profileDivESIOBuilderHTMLBtn = profileDivESIOBuilderBtn[
          i
        ] as HTMLElement;
        profileDivESIOBuilderHTMLBtn.onclick = () => {
          props.navigate("/ProcessLibrary/List");
          closeDropdownBtn();
          if (props.onUpdatMenuCategory) {
            props.onUpdatMenuCategory(MenuCategory.ESIOBuilder);
          }
        };
      }
    }

    if (isNotNullAndUndefined(profileDivSwitchOrganisationBtn)) {
      for (let i = 0; i < profileDivSwitchOrganisationBtn.length; i++) {
        let profileDivSwitchHTMLBtn = profileDivSwitchOrganisationBtn[
          i
        ] as HTMLElement;
        profileDivSwitchHTMLBtn.onclick = () => {
          closeDropdownBtn();
          OnClickNavigateToPortal(
            props.navigate,
            PortalDefaultView.MyOrganizationPortalView.DisplayName,
            setSelectedViewState
          );
        };
      }
    }
    if (isNotNullAndUndefined(profileDivUserLogOutBtn)) {
      for (let i = 0; i < profileDivUserLogOutBtn.length; i++) {
        let profileDivUserLogOutHTMLBtn = profileDivUserLogOutBtn[
          i
        ] as HTMLElement;
        profileDivUserLogOutHTMLBtn.onclick = () => {
          closeDropdownBtn();
          IsUserImpersonated().then(async (isImpersonatedUser) => {
            if (
              isNotNullAndUndefined(isImpersonatedUser) &&
              isImpersonatedUser === true
            ) {
              LogoutImpersonatedUser();
            } else {

              const awsToken = await getAWSToken();
              if (IsNotNullOrWhiteSpace(awsToken)) {
                const jwt: JWT = decodeJWT(awsToken);
                if (isNotNullAndUndefined(jwt) && isNotNullAndUndefined(jwt.payload) && isNotNullAndUndefined(jwt.payload['username'])) {
                  const jwtUsername = jwt.payload['username'] as string;
                  if (IsNotNullOrWhiteSpace(jwtUsername) && jwtUsername.toLowerCase().includes("microsoft")) {
                    localStorage.setItem("microsoftUser", "true");
                  }
                }
              }

              Logout(user ? signOut : null);
            }
          });
        };
      }
    }
    if (isNotNullAndUndefined(profileDivUserRefreshDataBtn)) {
      for (let i = 0; i < profileDivUserRefreshDataBtn.length; i++) {
        let profileDivUserRefreshDataHTMLBtn = profileDivUserRefreshDataBtn[
          i
        ] as HTMLElement;
        profileDivUserRefreshDataHTMLBtn.onclick = () => {
          closeDropdownBtn();
          RefreshUserStorage();
        };
      }
    }
  }

  const closeDropdownBtn = () => {
    let customDropdownMenu = document.querySelectorAll(".raf-ProfileCardDDB");
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
  };

  function switchOrganisationContent() {
    if (
      isNotNullAndUndefined(state.showSwitchOrganisationDialog) &&
      state.showSwitchOrganisationDialog === true
    ) {
      return (
        <SwitchOrganisation onClose={() => switchOrganisationDialogClose()} />
      );
    } else {
      return <div></div>;
    }
  }

  function switchOrganisationDialogOpen() {
    setState({ showSwitchOrganisationDialog: true });
  }

  function switchOrganisationDialogClose() {
    setState({ showSwitchOrganisationDialog: false });
  }

  function workspaceDialogClose() {
    setState({ showWorkspaceContent: false });
  }

  function workspaceContent() {
    if (state.showWorkspaceContent && state.showWorkspaceContent === true) {
      return (
        <SwitchWorkspace
          onClose={() => workspaceDialogClose()}
          {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  }

  const onMoreMenuClicked = (args: MenuEventArgs) => {
    closeDropdownBtn();
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
      const selectedOption = args.item.id;
      if (selectedOption === "SwitchOrganisation") {
        switchOrganisationDialogOpen();
      } else if (selectedOption === "SwitchTenant") {
        switchTenantDialogOpen();
      }
      console.log("selectedOption", selectedOption);
    }
  };

  //Tooltip react template

  const moreMenuOptions: ItemModel[] = [
    {
      id: "SwitchTenant",
      text: "Switch Tenant",
    },
    {
      id: "SwitchOrganisation",
      text: "Switch Organisation",
    },
  ];

  //SwitchOrganisation start
  const switchTenantDialogOpen = () => {
    setState({ showSwitchTenantDialog: true });
  };

  const switchTenantDialogContent = () => {
    if (state.showSwitchTenantDialog) {
      return (
        <SwitchTenant onClose={() => switchTenantDialogClose()} isActive />
      );
    } else {
      return <div></div>;
    }
  };

  const switchTenantDialogClose = () => {
    setState({ showSwitchTenantDialog: false });
  };
  //SwitchOrganisation end

  function tooltipTemplate(): JSX.Element {
    let htmlTooltipContent = (
      <div>
        <div
          className="e-card profile-card customScrollBar d-block"
          id="pageHeaderProfileCard"
        >
          <div className="e-card-header p-3">
            <div className="w-100">
              <div className="row flex-nowrap g-2 align-items-center">
                <div className="col-auto">
                  <UserProfilePhoto
                    uid={userId}
                    relatedToType={RAFEntityName.User}
                    canEdit={false}
                    profileImgCss={"profileImg-container-xmd"}
                    title={userName ?? "Not Set"}
                    iconSize="48"
                    fontSize="20"
                    emptyAvatarType="Initial"
                  />
                  {/* <div className={`e-avatar e-avatar-xlarge e-avatar-circle avatar-initial-palettes-${userNameInitial}`}>
                                    <span>{userNameInitial}</span>
                                </div> */}
                </div>
                <div className="col overflow-auto">
                  <div className="e-card-header-title mt-1">{userName}</div>
                  <div className="text-truncate">{userEmail}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="e-card-separator"></div>
          <div className="e-card-content d-flex justify-content-center px-4 ms-3">
            <div className="settingsItem w-100">
              <div className="d-flex align-items-center w-100">
                <span className="fa fa-sitemap me-2"></span>
                {isNotNullAndUndefined(currentPortalName) &&
                  selectedTenantName === currentPortalName ? (
                  <span className="ms-2 ecllipseFirstLine">
                    {selectedTenantName}
                  </span>
                ) : (
                  <div className="overflow-auto">
                    <div className="e-card-header-title mt-1 ecllipseFirstLine">
                      {selectedTenantName}
                    </div>
                    <div className="word-break-word ecllipseFirstLine">
                      {currentPortalName}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {/* {isNotNullAndUndefined(currentUserTenants) && currentUserTenants.length > 1 ?
                                <button id="profileDivSwitchOrganisationBtn" className="profileDivSwitchOrganisationBtn e-control e-btn e-lib e-outline e-info m-0">Switch</button>
                                :
                                ''
                            } */}
                {/* <DropDownButtonComponent
                                items={moreMenuOptions}
                                iconCss="fas fa-ellipsis-vertical"
                                className="raf-ProfileCardDDB e-caret-hide"
                                select={onMoreMenuClicked.bind(this)}
                            /> */}
              </div>
            </div>
          </div>
          <div className="e-card-content p-0 border-top">
            <button
              id="profileDivMyPortalsBtn"
              className="profileDivMyPortalsBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
            >
              <span
                className="e-btn-icon fa fa-briefcase e-icon-left"
                aria-hidden="true"
              ></span>
              My Portals
            </button>
            <button
              id="profileDivSwitchOrganisationBtn"
              className="profileDivSwitchOrganisationBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
            >
              <span
                className="e-btn-icon fa fa-briefcase e-icon-left"
                aria-hidden="true"
              ></span>
              Switch Organisation
            </button>
            {/* <button id="profileDivSwitchTenantBtn" className="profileDivSwitchTenantBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md" >
                        <span className="e-btn-icon fa fa-briefcase e-icon-left" aria-hidden="true"></span>
                        Switch Tenant
                    </button> */}
            {hasPermissionToAdministration === true && (
              <button
                id="profileDivAdministrationBtn"
                className="profileDivAdministrationBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
              >
                <span
                  className="e-btn-icon fa fa-tools e-icon-left"
                  aria-hidden="true"
                ></span>
                Administration
              </button>
            )}
            {hasPermissionToAdministration === true && (
              <button
                id="profileDivESIOBuilderBtn"
                className="profileDivESIOBuilderBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
              >
                <span
                  className="e-btn-icon fa fa-tools e-icon-left"
                  aria-hidden="true"
                ></span>
                ESIO Builder
              </button>
            )}
            {ISESIOAppAdministrator() && (
              <button
                id="profileDivSystemSettingsBtn"
                className="profileDivSystemSettingsBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
              >
                <span
                  className="e-btn-icon fas fa-user e-icon-left"
                  aria-hidden="true"
                ></span>
                System Settings
              </button>
            )}
            <button
              id="profileDivUserSettingsBtn"
              className="profileDivUserSettingsBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
            >
              <span
                className="e-btn-icon fas fa-user e-icon-left"
                aria-hidden="true"
              ></span>
              User Settings
            </button>
            {/* <button id="profileDivCategoryBtn" className="profileDivCategoryBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md">
                        <span className="e-btn-icon far fa-list e-icon-left" aria-hidden="true">
                        </span>
                        Category
                    </button> */}
            <button
              id="profileDivUserRefreshDataBtn"
              className="profileDivUserRefreshDataBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
            >
              <span
                className="e-btn-icon fa fa-retweet e-icon-left"
                aria-hidden="true"
              ></span>
              Refresh Data
            </button>
            <button
              id="profileDivUserLogOutBtn"
              className="profileDivUserLogOutBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
            >
              <span
                className="e-btn-icon fa fa-sign-out-alt e-icon-left"
                aria-hidden="true"
              ></span>
              Logout
            </button>
          </div>
        </div>
        <div
          className={
            isNotNullAndUndefined(props.arrowTipClass)
              ? `e-arrow-tip e-tip-top ${props.arrowTipClass}`
              : "e-arrow-tip e-tip-top"
          }
        >
          <div className="e-arrow-tip-outer e-tip-top"></div>
          <div className="e-arrow-tip-inner e-tip-top"></div>
        </div>
      </div>
    );
    return htmlTooltipContent;
    // <div id="democontent" className="democontent">
    //     <h3 style={{ marginTop: '10px' }}>Eastern Bluebird</h3>
    //     <img id="bird" src='./src/tooltip/images/bird.png' alt="bird_image" />
    //     <p>The
    //         <a href="https://en.wikipedia.org/wiki/Eastern_bluebird" target="_blank"> Eastern Bluebird </a>
    //         is easily found in open fields and sparse woodland areas, including along woodland edges. These are
    //         <i>cavity-nesting birds</i>
    //         and a pair of eastern bluebirds will raise 2-3 broods annually, with 2-8 light blue or whitish eggs
    //         per brood.</p>
    //     <hr style={{ marginTop: '10px 0px' }} />
    //     <p>Eastern bluebirds can be very vocal in flocks. Their calls include a rapid, mid-tone chatter and
    //         several long dropping pitch
    //         calls.</p>
    //     <p>Source:<br />
    //         <a href="https://en.wikipedia.org/wiki/Eastern_bluebird" target="_blank">https://en.wikipedia.org/wiki/Eastern_bluebird</a>
    //     </p>
    // </div>
  }

  return (
    <div className="w-100" id="div123">
      <div
        className="customViewDropdownMenu"
        id={"profileCardContentContainer"}
      >
        {tooltipTemplate()}
      </div>
      {props.uiMode === "fullBtn" ? (
        <DropDownButtonComponent
          id="ddbtnProfileCard"
          target={"#profileCardContentContainer"}
          cssClass={"raf-ProfileCardDDB raf_profile_card showArrowtip"}
          onClick={(e) => profileDropdownBtnClicked(e)}
        >
          <UserProfilePhoto
            uid={userId}
            relatedToType={RAFEntityName.User}
            canEdit={false}
            profileImgCss={"profileImg-container-normal"}
            title={userName ?? "Not Set"}
            iconSize="40"
            fontSize="14"
            // emptyAvatarType="Initial"
            emptyAvatar={
              <RAFIconImage
                iconCssClass={"fa fa-user"}
                moduleavatar="transparent-avatar"
                iconSize="40"
                fontSize="14"
              ></RAFIconImage>
            }
          />
          <div className="raf-ProfileCardDDB-text w-100 ps-2">
            <div className="subtitle_1 text-white word-break-word ecllipseFirstLine">
              {userName}
            </div>
            <div className="body_3 text-white word-break-word ecllipseFirstLine">
              {currentPortalName}
            </div>
          </div>
        </DropDownButtonComponent>
      ) : (
        <DropDownButtonComponent
          id="ddbtnProfileCard"
          target={"#profileCardContentContainer"}
          cssClass={
            "raf-ProfileCardDDB e-caret-hide ddbtnUserProfileCard customScrollBar showArrowtip"
          }
          iconCss={"fas fa-user"}
          onClick={(e) => profileDropdownBtnClicked(e)}
        >
          {/* <img src={avatarImg} className="cursor" alt="Avatar Img" /> */}
        </DropDownButtonComponent>
      )}
      {state.showSwitchOrganisationDialog && (
        <DialogComponent
          visible={state.showSwitchOrganisationDialog}
          header="Switch Organisation"
          showCloseIcon
          cssClass="centerDialog-sm create-dialog"
          content={switchOrganisationContent.bind(this)}
          isModal
          target="body"
          id="switchOrganisationContentDialog"
          close={switchOrganisationDialogClose.bind(this)}
        ></DialogComponent>
      )}
      {state.showSwitchTenantDialog && (
        <DialogComponent
          visible={state.showSwitchTenantDialog}
          header="Switch Tenant"
          showCloseIcon
          cssClass="centerDialog-sm create-dialog"
          content={switchTenantDialogContent.bind(this)}
          isModal
          target="body"
          id="switchTenantContentDialog"
          close={switchTenantDialogClose.bind(this)}
        ></DialogComponent>
      )}
      {state.showWorkspaceContent && (
        <DialogComponent
          header="Portals"
          showCloseIcon
          visible={state.showWorkspaceContent}
          cssClass="rightDialog createEditForm full-height workspace-dialog"
          content={workspaceContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={workspaceDialogClose.bind(this)}
        ></DialogComponent>
      )}
    </div>
  );
}

export default React.memo(withRouter(RAFProfileCard));
